import { links } from './data_link';
import { t } from './lang/lang.transform';

// depth2 page banner contents
const pageBannerContents = [
  {
    path: links.osp4111,
    page: t("pageTmaxos"),
    pageIntro: t("pageIntroTmaxos"),
    bannerClassName: "img_banner-subpage-1", // span tag's className, background-image
    depth2List: [
      {
        path: links.osp4111,
        page: t("pageTmaxosSub01"),
        pageIntro: t("pageIntroTmaxosSub01"),
      },
      {
        path: links.osp4116,
        page: t("pageTmaxosSub02"),
        pageIntro: t("pageIntroTmaxosSub02"),
      },
      {
        path: links.osp4117,
        page: t("pageTmaxosSub03"),
        pageIntro: t("pageIntroTmaxosSub03"),
      },
    ]
  },{
    path: links.osp5111,
    page: t("pageProduct"),
    pageIntro: t("pageIntroProduct"),
    bannerClassName: "img_banner-subpage-2",
    depth2List: [
      {
        path: links.osp5112,
        page: t("pageProductSub01"),
        pageIntro: t("pageIntroProductSub01"),
      },
      {
        path: links.osp5113,
        page: t("pageProductSub02"),
        pageIntro: t("pageIntroProductSub02"),
      },
      {
        path: links.osp5114,
        page: t("pageProductSub03"),
        pageIntro: t("pageIntroProductSub03"),
      }
    ]
  },{
    path: links.osp7116,
    page: t("pageCustomer"),
    pageIntro: t("pageIntroCustomer"),
    bannerClassName: "img_banner-subpage-4",
    depth2List: [
      {
        path: links.osp7116,
        page: t("pageCustomerSub01"),
        pageIntro: t("pageIntroCustomerSub01"),
      },
      {
        path: links.osp7121,
        page: t("pageCustomerSub02"),
        pageIntro: t("pageIntroCustomerSub02"),
      },
      {
        path: links.osp7126,
        page: t("pageCustomerSub03"),
        pageIntro: t("pageIntroCustomerSub03"),
      }
    ]
  }
];

export default pageBannerContents;