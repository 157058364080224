import { useState } from 'react';

import NoticeBoard from './Customer.NoticeBoard';
import TabBar from 'components/Tab/Tab.Bar';

const PageNoticeBoard = () => {
  const [tabIndex, setTabIndex] = useState(-1);

  const onClickTab = (event) => {
    const { target: { value } } = event;
    setTabIndex(Number(value));
  }

  return (
    <>
      <section className="section-osp7116 custom">
        <TabBar>
          <TabBar.Tab index={-1} value={tabIndex} label={"전체"} onClick={onClickTab} />
          <TabBar.Tab index={0} value={tabIndex} label={"일반"} onClick={onClickTab} />
          <TabBar.Tab index={1} value={tabIndex} label={"제품"} onClick={onClickTab} />
        </TabBar>
        <div className="width1352">
          {/* <InputSearchBoard page={page} /> */}
          <div className="board__wrapper notice">
            <div className="title">
              <span>분류</span>
              <span>제목</span>
              <span>날짜<i className="icon icon-sort" /></span>
            </div>
            <NoticeBoard sortIndex={tabIndex} />
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNoticeBoard;
