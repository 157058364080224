import React from 'react';

import ButtonScrollTop from 'components/Button/ButtonScrollTop';

import Header from 'layout/Header/Header';
import Footer from 'layout/Footer';

import TmaxOsRouter from 'routes/Router';

function App() {
  console.log(
    '%cHello, TmaxOS!', 'color: #2C99FF; font-weight: bold;',
  );
  console.log(`%c${process.env.REACT_APP_SERVICE_VERSION}`, 'color: #737373; background: linear-gradient(270deg, #44BCFF 0%, #4BA5FF 100%);')
  console.log("%csource code is here -> gitlab/sinri/os_site", 'color:  rgba(0, 0, 0, 0.01);')
  return (
    <>
      <div className="App">
        <Header />
        <TmaxOsRouter />
        <Footer />
        <ButtonScrollTop />
      </div>
    </>
  );
}

export default App;
