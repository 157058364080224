import { Link } from 'react-router-dom';

import pageBannerContents from 'data/data_page_banner';

const PageBanner = ({ page = 0, depth2Index = 0, children }) => {
  const siteInformation = pageBannerContents[page];
  const hasIndexPage =  page === 1 && depth2Index === -1 ; // 개요 페이지

  const pageTitle = () => {
    return hasIndexPage 
      ? siteInformation.page 
      : siteInformation.depth2List[depth2Index].page;
  };

  const pageExplain = () => {
    return hasIndexPage 
      ? siteInformation.pageIntro 
      : siteInformation.depth2List[depth2Index].pageIntro;
  };

  const Depth2List = ({ children }) => (
    <div className="subpage-depth2">
      <ul className="depth2__container w-default">{children}</ul>
    </div>
  );

  return (
    <section className="subpage-banner">
      <span className={`illust-subpage ${siteInformation.bannerClassName}`} />
      <Depth2List>
        {siteInformation.depth2List.map((depth2Information, index) => {
          const isFocused = !hasIndexPage && depth2Index === index;

          return (
            <li key={index}>
              <Link
                to={depth2Information.path}
                className={isFocused ? 'focused' : ''}
              >
                {depth2Information.page}
              </Link>
            </li>
          );
        })}
      </Depth2List>
      <div className="subpage-cont w-default">
        <h2>{pageTitle()}</h2>
        <p>{pageExplain()}</p>
      </div>
    </section>
  );
};

PageBanner.InputLabel = ({ placeHolder, value, setValue }) => {
  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onFocusLabelIcon = () => {
    const label = document.querySelector('.icon-search');
    label.classList.add('focused');
  };

  const onClickClose = () => {
    onFocusLabelIcon();
    setValue('');
  };

  return (
    <form className="search-banner">
      <label>
        <i className="icon-search">
          <svg
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.6308 15.7372C13.4207 16.7063 11.885 17.2859 10.214 17.2859C6.30856 17.2859 3.14258 14.1199 3.14258 10.2145C3.14258 6.30905 6.30856 3.14307 10.214 3.14307C14.1194 3.14307 17.2854 6.30905 17.2854 10.2145C17.2854 11.8855 16.7058 13.4211 15.7367 14.6313C15.7564 14.6475 15.7755 14.6648 15.7939 14.6832L19.5444 18.4337C19.8512 18.7405 19.8512 19.238 19.5444 19.5448C19.2375 19.8517 18.74 19.8517 18.4332 19.5448L14.6827 15.7944C14.6643 15.776 14.647 15.7569 14.6308 15.7372ZM15.714 10.2145C15.714 13.2521 13.2516 15.7145 10.214 15.7145C7.17644 15.7145 4.71401 13.2521 4.71401 10.2145C4.71401 7.17693 7.17644 4.71449 10.214 4.71449C13.2516 4.71449 15.714 7.17693 15.714 10.2145Z"
              fill="#C4C4C4"
            />
          </svg>
        </i>
      </label>

      <input
        name="search"
        id="search"
        type="text"
        className={value.length > 0 ? 'typing' : ' '}
        placeholder={placeHolder}
        value={value}
        onChange={(event) => onChange(event)}
        onFocus={() => onFocusLabelIcon()}
      />

      {value.length > 0 && (
        <button className="btn-close" onClick={onClickClose}>
          <i className="icon icon-search_clear">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.9322 5.05027C4.68457 5.29878 4.68457 5.7017 4.9322 5.95022L8.96767 10L4.93224 14.0498C4.6846 14.2983 4.6846 14.7013 4.93224 14.9498C5.17987 15.1983 5.58137 15.1983 5.829 14.9498L9.86444 10.9L13.8999 14.9498C14.1475 15.1983 14.549 15.1983 14.7966 14.9498C15.0443 14.7012 15.0443 14.2983 14.7966 14.0498L10.7612 10L14.7967 5.95023C15.0443 5.70171 15.0443 5.29879 14.7967 5.05027C14.549 4.80176 14.1475 4.80176 13.8999 5.05027L9.86444 9.10008L5.82897 5.05027C5.58134 4.80175 5.17984 4.80175 4.9322 5.05027Z"
                fill="#797979"
              />
            </svg>
          </i>
        </button>
      )}
    </form>
  );
};

PageBanner.ButtonDownload = ({ label = '다운로드' }) => {
  return (
    <a
      href="#download"
      className="btn-rounded-02 btn-download-tmaxgr"
      download={false}
    >
      {label}
    </a>
  );
};

export default PageBanner;
