import PageBanner from 'components/Banner/Banner.Page';

import TabBoxed from 'components/Tab/Tab.Boxed';
import TabContent from 'components/Tab/Tab.Content';

export default function OSP4116() {
  return (
    <>
      <PageBanner page={0} depth2Index={1} />
      <main className="main-osp4116">
        <TabBoxed content="history" main="main-osp4116">
          <TabBoxed.Col id={'osp41162023'} item={'현재 ~ 2019'} index={0} />
          <TabBoxed.Col id={'osp41162018'} item={'2018 ~ 2015'} index={1} />
          <TabBoxed.Col id={'osp41162010'} item={'2010 ~ 2003'} index={2} />
        </TabBoxed>

        <h1>
          국산 OS 전문 기업에서
          <strong>개방형 OS 및 원격 솔루션 기업으로 성장</strong>
        </h1>

        <section className="history__container">
          <TabContent id="osp41162023">
            <div className="history h--01 --right">
              <span className="img img_history-1"></span>
              <div className="history-content">
                <h3>OS 사업영역 확장</h3>
                <ul>
                  <li>
                    <p>
                      <strong>2023</strong>임베디드/모빌리티 사업 확장
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2022</strong>원격접속 솔루션 사업 확장
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="history --left">
              <span className="img img_history-2"></span>
              <div className="history-content">
                <h3>국산 OS 대표주자</h3>
                <ul className="--pcview">
                  <li>
                    <p>
                      Tmax구름 개발<strong>2021</strong>
                    </p>
                  </li>
                  <li>
                    <p>
                      TmaxOS3 출시<strong>2019</strong>
                    </p>
                  </li>
                </ul>

                <ul className="--mobileview">
                  <li>
                    <p>
                      <strong>2021</strong>Tmax구름 개발
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2019</strong>TmaxOS 3 출시
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </TabContent>
          <TabContent id="osp41162018">
            <div className="history --right">
              <span className="img img_history-3"></span>
              <div className="history-content">
                <h3>시스템 SW 전문기업 탄생</h3>
                <ul>
                  <li>
                    <p>
                      <strong>2018</strong>GS(Good Software) 1등급
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2015</strong>TmaxOS 개발 재개
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </TabContent>
          <TabContent id="osp41162010">
            <div className="history --left">
              <span className="img img_history-4"></span>
              <div className="history-content">
                <h3>OS 개발 시작</h3>
                <ul className="--pcview">
                  <li>
                    <p>
                      티맥스 코어, 삼성 SDS에 매각<strong>2010</strong>
                    </p>
                  </li>
                  <li>
                    <p>
                      운영체제 개발 시작<strong>2003</strong>
                    </p>
                  </li>
                </ul>
                <ul className="--mobileview">
                  <li>
                    <p>
                      <strong>2010</strong>티맥스 코어, 삼성 SDS에 매각
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>2003</strong>운영체제 개발 시작
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </TabContent>
        </section>
      </main>
    </>
  );
}
