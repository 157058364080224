import { Link } from 'react-router-dom';

const CustomerNoticeContentsContainer = ({ props }) => {
  const notice = { 
    // default
    title: 'default title',
    path: '/customer/notice',
    category: '일반',
    category_id: 0,
    date: '0000-00-00',
    notice_state: false,

    ...props,
  };

  const handleNewState = () => {
    const nowDay = new Date();
    const contentDay = new Date(notice.date);
    const conditionDay = new Date(notice.date);
    conditionDay.setDate(contentDay.getDate() + 7);

    return conditionDay > nowDay;
  }

  return (
    <>
      <Col1>
        <span className={notice.category_id === 0 ? 'cate' : 'cate --pr'}>
          {notice.category}
        </span>
      </Col1>
      <Link
        to={notice.path}
        className={notice.notice_state ? 'cont notice' : 'cont'}
      >
        <span className='cont-title'>{notice.title}</span>
        {handleNewState() && <i className="icon icon-table_new"></i>}
      </Link>
      <Col3>
        <span className="date">{notice.date}</span>
      </Col3>
    </>
  );
};

export default CustomerNoticeContentsContainer;

const Col1 = ({ children }) => {
  return <div className="--col1" aria-colindex={1}>
    {children}
  </div>
}

const Col3 = ({ children }) => {
  return <div className="--col3" aria-colindex={3}>
    {children}
  </div>
}