import React from "react";
import Portal from "./Portal";

const Dialog = ({children, className}) => {

  return <Portal>
    <div className={`dialog-container ${className}`}> 
      <div className="dialog">
        {children}
      </div>
    </div>
  </Portal>
}

export default Dialog;