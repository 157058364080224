/**
 * @sortIndex -1 전체 0 일반 1 제품
 * 
 * 1. 시간순 전체 게시판 정렬
 * 2. 분류별 게시판 정렬 
 * 3. 10개씩 자르기
 * 4. 공지사항 먼저 배치 
 * 
 * TODO: 페이지네이션 10개 넘을 때 기능 구현
 */
import React, { useState } from 'react';

import { customerNotice } from 'data/data_customer';

import CustomerNoticeContentsContainer from './Customer.NoticeContents';
import Pagination, { ButtonPageArrow } from 'components/Pagination';

const renderSortedBoardList = (boardList) => {
  return <> {
    boardList.map((board, index) => {
      return <li key={index}>
        <CustomerNoticeContentsContainer props={board} />
      </li>
    })
  } </>
}

const NoticeBoard = ({ sortIndex=-1 }) => {
  const [pageIndex, setPageIndex] = useState(0);
  
  const boardList = [...customerNotice]
    .sort((a,b) => new Date(b.date) - new Date(a.date))
    .filter(board => sortIndex !== -1 ? board.category_id === sortIndex : board);

  const totalPage = Math.ceil(boardList.length / 10);
  const pagedBoardList = [...boardList].slice(pageIndex*10, (pageIndex + 1)*10);
  const noticedBoardList = pagedBoardList.filter(board => board.notice_state);
  const normalBoardList = pagedBoardList.filter(board => !board.notice_state);

  const onClickPagination = (event) =>{
    setPageIndex(Number(event.target.value))
  };

  return <>
    <ul className="board-list">
      {renderSortedBoardList(noticedBoardList)}
      {renderSortedBoardList(normalBoardList)}
    </ul>
    <Pagination>
      <ButtonPageArrow arrow={"beforeMore"} 
        disabled={pageIndex-totalPage <= 0}
        />
      <ButtonPageArrow arrow={"before"}
        disabled={pageIndex===0}
        onClick={()=>setPageIndex(pageIndex-1)}
      />
      <Pagination.List 
        total={totalPage} 
        pageIndex={pageIndex} 
        onClick={onClickPagination}
      />
      <ButtonPageArrow arrow={"next"} 
        disabled={pageIndex===totalPage-1}
        onClick={()=>setPageIndex(pageIndex+1)}
      />
      <ButtonPageArrow arrow={"nextMore"} />
    </Pagination>
  </>
};

export default NoticeBoard;