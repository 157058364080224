import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { regBr } from 'hooks/useRegExp';

import PageBanner from 'components/Banner/Banner.Page';
import { IconMoreArrow } from 'components/common/Icons';

import { product_list } from 'data/data_product';
import { links } from 'data/data_link';

export default function OSP5111() {
  const scrollEvent = () => {
    const sectionProducts = document.querySelectorAll('.product');

    let options = {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0.8, 1.0],
    };

    const callback = (entries) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        entry.target.classList.add('--visible');
        return;
      } else {
        // entry.target.classList.remove('--visible');
      }
    };

    let observer = new IntersectionObserver(callback, options);

    sectionProducts.forEach((element) => {
      observer.observe(element);
    });
  };

  const scrollToContent = (id) => {
    const target = document.querySelector(`#${id}`);
    const header = document.querySelector('.header');

    const criterion = Math.floor(
      window.scrollY + target.getBoundingClientRect().top
    );
    window.scrollTo(0, criterion - header.clientHeight);
  };

  useEffect(() => {
    const product = window.location.hash.split('/');
    switch (product.pop()) {
      case '0':
        scrollToContent(product_list[0].id);
        break;
      case '1':
        scrollToContent(product_list[1].id);
        break;
      case '2':
        scrollToContent(product_list[2].id);
        break;
      default:
    }

    window.addEventListener('scroll', scrollEvent);
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  return (
    <>
      <PageBanner page={1} depth2Index={-1} />
      <main className="main-osp5111">
        <section className="tmaxos-products background-01">
          <h3>
            TmaxOS의 다양한 제품을 <br />
            <strong>2022 Tmax Super Week</strong>에서 직접 확인해 보세요!
          </h3>

          <div className="products-list__container w-default">
            <ul>
              {product_list.map((item, index) => {
                const { name, explain, m_explain } = item;

                const filteredExplain = explain.replace(regBr, '<br />');
                const filteredmExplain = m_explain.replace(regBr, '<br />');

                return (
                  <li key={index}>
                    <Link className="btn-product" to={item.path}>
                      <span className="product-name">{name}</span>
                      <p
                        className="product-cont --pcview"
                        dangerouslySetInnerHTML={{ __html: filteredExplain }}
                      ></p>
                      <p
                        className="product-cont --mobile"
                        dangerouslySetInnerHTML={{ __html: filteredmExplain }}
                      ></p>
                      <div className="product-more">
                        <span className="btn btn-more">
                          <span>자세히 보기</span>
                          <IconMoreArrow />
                        </span>
                        {index === 0 && (
                          <Link
                            to={links.osp7126}
                            className="btn btn-rounded-03"
                          >
                            <span>다운로드</span>
                          </Link>
                        )}
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>

        <section className="product white" id={product_list[0].id}>
          <div className="product__container w-default">
            <span className="img img_feature-1"></span>
            <div className="feature-content">
              <h4>
                편리하다. 안전하다. 강력하다.
                <strong>Tmax구름</strong>
              </h4>
              <p>
                데비안 리눅스 기반 개방형 운영체제로 <br />
                강력한 보안 모듈을 탑재하여 다양한 사용자가 <br />
                편리하고 안전하게 사용할 수 있는 OS 환경을 제공합니다.
              </p>
            </div>
          </div>
        </section>

        <section className="product gray" id={product_list[1].id}>
          <div className="product__container w-default">
            <div className="feature-content">
              <h4>
                보안 단말의 표준을 제시하는
                <strong>Tmax구름 SD</strong>
              </h4>
              <p>
                Tmax구름의 검증된 보완성 및 사용성을 기반으로 <br />
                사용자 환경에 최적화된 보안 단말 입니다.
              </p>
            </div>
            <span className="img img_feature-2"></span>
          </div>
        </section>

        <section className="product white" id={product_list[2].id}>
          <div className="product__container w-default">
            <span className="img img_feature-3"></span>
            <div className="feature-content">
              <h4>
                안전하고 편리한 <br />
                원격 근무 환경을 지원하는
                <strong>SuperRDS</strong>
              </h4>
              <p>
                Tmax구름 SD와 결합한 안전하고 간편한 원격 접속 솔루션입니다.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
