const promotionEndDay = new Date('2023-06-30'); // 프로모션 기간: ~6/30 

const today = new Date();

// 기한을 넘겼습니까?
const isOverEndDay = (endDay) => {
  return today > endDay;
}

export {
  promotionEndDay,
  isOverEndDay,
}