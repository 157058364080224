import { Link } from 'react-router-dom';
import { regBr } from 'hooks/useRegExp';

import { IconMoreArrow } from 'components/common/Icons';

import { links } from 'data/data_link';
import { product_list } from 'data/data_product';

const MainProducts = () => {
  // let navigate = useNavigate();

  const filteredExplain = (text) => {
    return text.replace(regBr, '<br />');
  };

  return (
    <>
      <section className="main-products">
        <div className="product__container w-default">
          <h3>
            <span>선택의 기쁨, 혁신의 시작</span>
            <span>티맥스오에스의 다양한 제품을 만나보세요.</span>
          </h3>
          <div className="product__btns">
            <Link to={links.osp5112} className="btn-product">
              {/* <button
            className='btn-product'
            onClick={()=> {
              navigate('/product/0');
            }}
          > */}
              <span className="img img_feature-1"></span>
              <h4>{product_list[0].name}</h4>
              <p
                className='--pcview'
                dangerouslySetInnerHTML={{
                  __html: filteredExplain(product_list[0].explain),
                }}
              ></p>
              <p
                className='--mobileview'
                dangerouslySetInnerHTML={{
                  __html: filteredExplain(product_list[0].m_explain),
                }}
              />
              <span className="btn btn-more">
                자세히 보기
                <IconMoreArrow />
              </span>
              {/* </button> */}
            </Link>

            <Link to={links.osp5113} className="btn-product">
              {/* <button
            className='btn-product'
            onClick={()=> {
              navigate('/product/1');
            }}
          > */}
              <span className="img img_feature-2"></span>
              <h4>{product_list[1].name}</h4>
              <p
                className='--pcview'
                dangerouslySetInnerHTML={{
                  __html: filteredExplain(product_list[1].explain),
                }}
              ></p>
              <p
                className='--mobileview'
                dangerouslySetInnerHTML={{
                  __html: filteredExplain(product_list[1].m_explain),
                }}
              />
              <span className="btn btn-more">
                <span>자세히 보기</span>
                <IconMoreArrow />
              </span>
              {/* </button> */}
            </Link>

            <Link to={links.osp5114} className="btn-product">
              {/* <button
            className='btn-product'
            onClick={()=> {
              navigate('/product/2');
            }}
          > */}
              <span className="img img_feature-3"></span>
              <h4>{product_list[2].name}</h4>
              <p
                className='--pcview'
                dangerouslySetInnerHTML={{
                  __html: filteredExplain(product_list[2].explain),
                }}
              ></p>
              <p
                className='--mobileview'
                dangerouslySetInnerHTML={{
                  __html: filteredExplain(product_list[2].m_explain),
                }}
              />
              <span className="btn btn-more">
                <span>자세히 보기</span>
                <IconMoreArrow />
              </span>
              {/* </button> */}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainProducts;
