import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { regBr, regSpace } from 'hooks/useRegExp';

import CustomerNoticeContentsContainer from './Customer.NoticeContents';

import { customerNotice } from 'data/data_customer';

export default function OSP7117() {
  let params = useParams();
  const index = params.id;

  const notice = customerNotice[index];
  const noticeText = notice.content.replace(regBr, '<br />').replace(regSpace, '<span>&nbsp;&nbsp;&nbsp;</span>');

  return (
    <>
      <div className="div-osp7117 custom">
        <div className="board__wrapper width1352 notice">
          <ul className="board-list">
            <li>
              <CustomerNoticeContentsContainer props={notice} />
            </li>
          </ul>
        </div>

        <div className="board-detail width1352">
          <p dangerouslySetInnerHTML={{ __html: noticeText }}></p>
        </div>

        <Link to={'/customer/notice'} className="btn-link-list">
          <i className="icon icon-btn-table-list" />
          <span>목록 보기</span>
        </Link>
      </div>
    </>
  );
}
