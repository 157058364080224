import { useState } from 'react';

import PageBanner from 'components/Banner/Banner.Page';
import TabBar from 'components/Tab/Tab.Bar';

import { t } from 'data/lang/lang.transform';

const OSP4117 = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const onClickTab = (event) => {
    const { target: { value } } = event;
    setTabIndex(Number(value));
  }

  const renderTabContents = (number) => {
    switch (number) {
      case 0:
        return <OSP4117.MapHeadQuarter />;
      case 1:
        return <OSP4117.MapRnD />;
      default:
        return <>잘못된 접근 history 관리하기</>;
    }
  };

  return (
    <>
      <PageBanner page={0} depth2Index={2} />
      <main className="main-osp4117">
        <TabBar>
          <TabBar.Tab index={0} value={tabIndex} label={'본사'} onClick={onClickTab} />
          <TabBar.Tab index={1} value={tabIndex} label={'R&D센터'} onClick={onClickTab} />
        </TabBar>
        {renderTabContents(tabIndex)}
      </main>
    </>
  );
};

OSP4117.MapHeadQuarter = () => {
  return (
    <div className="map__wrapper w-default">
      <Address address={t("ADDRESS_HQ")} />
      <iframe
        title="temp_tmaxmap"
        className="temp-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3170.4575569209464!2d127.1139506765114!3d37.379010172087824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b5818479c93f5%3A0x42c5786d260726c8!2z67aE64u57JiI66-47KeA67mM65Sp!5e0!3m2!1sko!2skr!4v1686126311289!5m2!1sko!2skr" 
        allow='fullscreen'
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
};

OSP4117.MapRnD = () => {
  return (
    <div className="map__wrapper w-default">
      <Address address={t("ADDRESS_RND")} />
      <iframe
        title="temp_tmaxmap"
        className="temp-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d371.9537290681778!2d127.1049137433035!3d37.35377895182635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b59c807e1bde1%3A0xb18536260c91adc1!2z6rK96riw64-EIOyEseuCqOyLnCDrtoTri7nqtawg7KCV7J6Q7J2866GcIDQ1!5e0!3m2!1sko!2skr!4v1686126494204!5m2!1sko!2skr"
        allow='fullscreen'
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        // onLoad={() => onLoad()}
      />
    </div>
  );
};

const Address = ({address}) => {
  return <address>
    <span className="addr-map">
      <i className="icon icon-circle-blue"></i>
      {address}
    </span>
    <a href="tel:+82 18005566" className="btn-addr">
      <span>Tel</span>{t('inforContactNumber')}&#40;{t("inforWorkTime")}&#41;
    </a>
    <a href={"mailto:" + t("inforEmail")} className="btn-addr">
      <span>Mail</span>{t("inforEmail")}
    </a>
  </address>
}

export default OSP4117;
