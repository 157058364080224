import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NavPc from './Header.Nav.Pc';
import NavMobile from './Header.Nav.Mobile';

const Header = () => {
  const [path, setPath] = useState(-1);

  const location = useLocation();

  const handleSubPageIndex = (pathname = '-') => {
    switch (pathname) {
      case 'tmaxos':
        return 0;
      case 'product':
        return 1;
      case 'customer':
        return 2;
      default:
        return -2;
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });

    const handlePageCategory = (pathname = '-') => {
      if (pathname === '/') {
        setPath(-1); // main
      } else {
        setPath(handleSubPageIndex(pathname?.split('/')[1]));
      }
    };

    handlePageCategory(location.pathname);

    return () => {};
  }, [location]);


  useEffect(() => {
    const scrollInteraction = () => {
      const App = document.querySelector('.App');
  
      if (window.scrollY > 0) {
        App?.classList.add('down');
      } else if (window.scrollY === 0) {
        App?.classList.remove('down');
      }
    };

    window.addEventListener('scroll', scrollInteraction);
    return () => {
      window.removeEventListener('scroll', scrollInteraction);
    };
  }, []);

  return (
    <header className={path === -1 ? 'header header-main' : 'header header-sub'}>
      <NavPc path={path} />
      <NavMobile />
    </header>
  );
};

export default Header;
