export const links = {
  // main - osp3111
  "main": "/",
  // tmaxos TmaxOS 소개
  "osp4111": "/tmaxos",
  "osp4116": "/tmaxos/history",
  "osp4117": "/tmaxos/map",
  // product 제품
  "osp5111": "/product/",
  "osp5112": "/product/tmaxgr",
  "osp5113": "/product/sd",
  "osp5114": "/product/rds",
  // promote
  // "osp6111": "/promote",
  // "osp6115": "/promote/board",
  // "osp6116": "/promote/board/:id",
  // "osp6112": "/promote/news",
  // "osp6113": "/promote/news/:id",
  // "osp6114": "/promote/video",
  // customer
  // "osp7111": "/customer", // 개요페이지 삭제 
  "osp7116": "/customer/notice",
  "osp7117": "/customer/notice/:id",
  // "osp7120": "/customer/qna",
  "osp7121": "/customer/contact", // 문의하기(임시 레이아웃)
  "osp7126": "/customer/down", 
  // grcommu
  // "osp0111": "/grcommu",
  // "osp0112": "/grcommu/devguide",
  // etc
  // "osp8112": "/policy/privacy", // TODO: 정책 자료 필요

  // error
  "error404": "/notfound",

  "resources": "https://sourcecode.tmaxos.com:4000/",
  "HR": "https://inky-viscose-389.notion.site/TmaxOS-2f38bb3b938d4a358c6258cf91cc8d24",

  // Tmax구름.iso 다운로드 링크 (PS 제작)
  // "grDownload-03.00": "https://osdownload.tmaxos.com:55556/download/TmaxOS/tmaxgooroom-21-common-21.23.03.00_rc1.iso",
  "grDownload": "https://osdownload.tmaxos.com:55556/download/TmaxOS/tmaxgooroom-21-common-21.23.03.01.iso",
  "osLetter": "https://page.stibee.com/subscriptions/259228",
};