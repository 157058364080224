const assets = {
  "inforContactNumber": "1800 - 5566",
  "inforWorkTime": "평일 09:00 ~ 18:00",
  "inforEmail": "tmaxossupport@tmax.co.kr",
  "inforCEO": "홍강식",
  "inforWorkers": "101",
  "inforFound": "2015. 12. 01",

  "HQ": "본사",
  "ADDRESS_HQ": "경기 성남시 분당구 황새울로258번길 31, 분당예미지빌딩 3층",
  "RnD": "R&D센터",
  "ADDRESS_RND": "경기 성남시 분당구 정자일로 45"
};

export const kr = {
  ...assets,
  "lbNotice": "공지사항",
  "lbBtnAll": "전체보기",

  // tmaxos
  "pageTmaxos": "TmaxOS 소개",
  "pageIntroTmaxos": '개방형 OS 시장을 선도하고 신사업을 발굴해 다방면의 서비스를 제공합니다.',

  "pageTmaxosSub01": "회사소개",
  "pageIntroTmaxosSub01": "개방형 OS 시장을 선도하고 신사업을 발굴해 다방면의 서비스를 제공합니다.",
  "pageTmaxosSub02": "연혁",
  "pageIntroTmaxosSub02": "대한민국을 넘어 글로벌 SW기업으로 도약하기 위해 도전하고 상상합니다.",
  "pageTmaxosSub03": "오시는 길",
  "pageIntroTmaxosSub03": "대한민국을 넘어 글로벌 SW기업으로 도약하기 위해 도전하고 상상합니다.",


  // products
  "pageProduct": "제품",
  "pageIntroProduct": "더욱 빠르고 편리하게, TmaxOS를 통해 원격 근무에 가장 최적화된 솔루션을 경험해 보세요.",
  
  "pageProductSub01": "Tmax구름",
  "pageIntroProductSub01": "국가보안기술연구소의 구름 보안 프레임워크에 \nTmaxOS 자체 개발 기술력으로 완성한 개방형 운영 체제",
  "pageProductSub02": "Tmax구름 SD",
  "pageIntroProductSub02": "강력한 보안성과 사용성을 기반으로 원격접속 단말기에 최적화된 개방형OS입니다.",
  "pageProductSub03": "SuperRDS",
  "pageIntroProductSub03": "Tmax구름 SD와 결합한 안전하고 간편한 원격 접속 솔루션입니다.",

  // customer
  "pageCustomer": "고객지원",
  "pageIntroCustomer": "티맥스오에스에 대한 궁금증을 편리하게 해결하세요.",

  "pageCustomerSub01": "공지사항",
  "pageIntroCustomerSub01": "회사와 제품에 대한 새로운 소식을 전달합니다.",
  "pageCustomerSub02": "문의하기",
  "pageIntroCustomerSub02": "티맥스오에스에 대해 궁금한 점을 문의해 주세요.",
  "pageCustomerSub03": "다운로드",
  "pageIntroCustomerSub03": "Tmax구름을 무료로 체험해 보세요.",

};