import React from "react";

const Pagination = ({ list = [], children, total }) => {
  return <nav className="pagination">
    {children}
  </nav>
}

export default Pagination;

Pagination.List = ({total, pageIndex, onClick}) => {
  const paginationList = Array.from({ length: total }, (_, x) => x+1);

  return <ol className="pagination-list">
    {
      paginationList.map((num, index) => {
        return <li key={index}>
          <button
            className="btn btn-page-number"
            onClick={onClick}
            value={index}
            aria-pressed={pageIndex === index}
          >
            {num}
          </button>
        </li>
      })
    }
  </ol>
}

//------------------------------------------------------------------------------

const ButtonPageArrow = ({ arrow, onClick, disabled=true }) => {
  const icon = {
    "before": <IconBefore />,
    "beforeMore": <IconBeforeMore />,
    "next": <IconNext />,
    "nextMore": <IconNextMore />
  }

  return <button 
    className="btn btn-page-arrow"
    disabled={disabled}
    onClick={onClick}
  >
    {icon[arrow]}
  </button>
}

export { ButtonPageArrow };

//------------------------------------------------------------------------------

const IconBefore = () => (
  <i className="icon">
    <svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Icon/Page/Before</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2924 20.7071C13.6829 20.3166 13.6829 19.6834 13.2924 19.2929L8.83053 14.8311L13.293 10.3685C13.6836 9.97801 13.6836 9.34485 13.293 8.95432C12.9025 8.5638 12.2694 8.5638 11.8788 8.95432L6.22198 14.6112L6.44117 14.8304L6.2213 15.0503L11.8782 20.7071C12.2687 21.0976 12.9018 21.0976 13.2924 20.7071Z" fill="#DDDDDD"/>
    </svg>
  </i>
);

const IconBeforeMore = () => (
  <i className="icon">
    <svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2924 20.7071C15.6829 20.3166 15.6829 19.6834 15.2924 19.2929L10.8305 14.8311L15.293 10.3685C15.6836 9.97801 15.6836 9.34485 15.293 8.95432C14.9025 8.5638 14.2694 8.5638 13.8788 8.95432L8.22198 14.6112L8.44117 14.8304L8.2213 15.0503L13.8782 20.7071C14.2687 21.0976 14.9018 21.0976 15.2924 20.7071Z" fill="#DDDDDD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.70643 20.7071C10.097 20.3166 10.097 19.6834 9.70643 19.2929L5.24459 14.8311L9.70711 10.3685C10.0976 9.97801 10.0976 9.34485 9.70711 8.95432C9.31658 8.5638 8.68342 8.5638 8.29289 8.95432L2.63604 14.6112L2.85524 14.8304L2.63536 15.0503L8.29221 20.7071C8.68274 21.0976 9.3159 21.0976 9.70643 20.7071Z" fill="#DDDDDD"/>
    </svg>
  </i>
);

const IconNext = () => (
  <i className="icon">
    <svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.70764 9.29289C6.31711 9.68342 6.31711 10.3166 6.70764 10.7071L11.1695 15.1689L6.70696 19.6315C6.31643 20.022 6.31643 20.6552 6.70696 21.0457C7.09748 21.4362 7.73064 21.4362 8.12117 21.0457L13.778 15.3888L13.5588 15.1696L13.7787 14.9497L8.12185 9.29289C7.73132 8.90237 7.09816 8.90237 6.70764 9.29289Z" fill="#797979"/>
    </svg>
  </i>
);

const IconNextMore = () => (
  <i className="icon">
    <svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.70764 9.29289C4.31711 9.68342 4.31711 10.3166 4.70764 10.7071L9.16947 15.1689L4.70696 19.6315C4.31643 20.022 4.31643 20.6552 4.70696 21.0457C5.09748 21.4362 5.73064 21.4362 6.12117 21.0457L11.778 15.3888L11.5588 15.1696L11.7787 14.9497L6.12185 9.29289C5.73132 8.90237 5.09816 8.90237 4.70764 9.29289Z" fill="#797979"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.2936 9.29289C9.90305 9.68342 9.90305 10.3166 10.2936 10.7071L14.7554 15.1689L10.2929 19.6315C9.90237 20.022 9.90237 20.6552 10.2929 21.0457C10.6834 21.4362 11.3166 21.4362 11.7071 21.0457L17.364 15.3888L17.1448 15.1696L17.3646 14.9497L11.7078 9.29289C11.3173 8.90237 10.6841 8.90237 10.2936 9.29289Z" fill="#797979"/>
    </svg>
  </i>
);
