import React from 'react';

import PageBanner from 'components/Banner/Banner.Page';
import { TabBoxedStyle } from 'components/Tab/TabStyle';
// import TabBoxed from 'components/common/Tab/Tab.Boxed';
import NoticeText from './Product.NoticeText';
import CustomerBanner from 'components/Banner/Banner.Customer';

export default function OSP5112() {
  return (
    <>
      <PageBanner page={1} depth2Index={0} />
      <main className="main-osp5112">
        <TabBoxedStyle
          props={{
            list: ['제품 소개', '특장점', '활용 분야'],
            id: ['osp511201', 'osp511202', 'osp511203'],
            page: 'osp5112',
          }}
        />
        <TmaxgrFeatureContainer />
        <NoticeText label={"Tmax구름 소개서"} product={"Tmax구름"} />
        <CustomerBanner />
      </main>
    </>
  );
}

const TmaxgrFeatureContainer = () => {
  return <React.Fragment>
            {/* 티맥스 구름 특징 - 메인 */}
            <section id="osp511201" className="tmaxgr-feature--main">
          <h1>편리하다. 안전하다. 강력하다.</h1>

          <p className="main-title-cont">
            Tmax구름은 강력한 보안성과 친숙한 사용성으로 <br />
            다양한 사용자가 편리하고 안전하게 사용할 수 있습니다.
          </p>

          <span className="img img-tmaxgr-feature"></span>

          {/* 메인 - 특징 리스트 */}
          <ul className="feature-list w-default">
            <li>
              <i className="icon icon-tmaxgr-feature-sec"></i>
              <h5>강력한 보안</h5>
              <p>
                국보연 보안모듈을 탑재하여 <br />
                안전한 사용 환경 제공
              </p>
            </li>
            <li className="--centered">
              <i className="icon icon-tmaxgr-feature-vos"></i>
              <h5>Virtual OS 제공</h5>
              <p>
                VM(가상머신)을 개별적으로 설치할 필요 없이 <br />
                멀티 OS 환경 지원 <br />
                (Win, Linux, CentOS)
              </p>
            </li>
            <li>
              <i className="icon icon-tmaxgr-feature-uiux"></i>
              <h5>간결하고 편리한 UI/UX</h5>
              <p>
                처음 사용자도 친숙하고 편안하게 <br />
                사용 가능한 UI/UX 제공
              </p>
            </li>
          </ul>
        </section>
        {/* 티맥스 구름 특징 - 제품소개 (특장점) */}
        <section id="osp511202" className="tmaxgr-feature--info">
          <div className="info-sec">
            <h3>강력한 보안 환경</h3>

            <div className="info-sec__container">
              <div className="sec-title">
                <h4>
                  <span className="basic">
                    국가보안기술연구소의 보안 모듈을 탑재한
                  </span>
                  <br />
                  <span className="highlight">안전한 개방형 OS</span>
                </h4>
              </div>

              <div className="sec-cont">
                <ul className="sec-list">
                  <li>
                    <h5>신뢰 부팅</h5>
                    <p>커널 무결성 검증</p>
                    <p>부팅 차단</p>
                  </li>
                  <li>
                    <h5>실행파일 보호</h5>
                    <p>저장 data 암호화</p>
                    <p>비인가 프로그램 실행 차단</p>
                  </li>
                  <li>
                    <h5>디스크 암호화</h5>
                    <p>저장 data 암호화</p>
                  </li>
                  <li>
                    <h5>운영체제 보호</h5>
                    <p>커널 런타임 무결성 검증</p>
                    <p>커널 런타임 무결성 검증</p>
                  </li>
                  <li>
                    <h5>브라우저 보호</h5>
                    <p>신뢰/비신뢰로 격리된 환경</p>
                    <p>차등화 된 보안정책 적용</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="info-sol">
            <h3>OS 중앙관리 솔루션</h3>
            <p>
              국가보안기술연구소 GPMS(구름플랫폼관리시스템)에 강화된 <br />
              보안 및 추가 관리 기능 제공
            </p>

            <div className="info-sol__container">
              <div className="sol-cont">
                <ul className="sol-list">
                  <li className="--right">
                    <h5>매체 제어</h5>
                    <p>저장 매체 및 입출력기기 통제</p>
                    <p>OS접근 허용/차단 관리</p>
                  </li>
                  <li className="--left">
                    <h5>SW 제어</h5>
                    <p>화이트리스트 기반 SW 제어</p>
                    <p>SW 사용 현황 모니터링</p>
                  </li>
                  <li className="--right">
                    <h5>네트워크 제어</h5>
                    <p>네트워크 연결 허용/차단 제어</p>
                    <p>화이트리스트 기반 SSID 관리</p>
                  </li>
                  <li className="--left">
                    <h5>웹사이트 통제</h5>
                    <p>웹사이트 접속 URL 통제</p>
                    <p>화이트/블랙리스트 선택 통제</p>
                  </li>
                </ul>
              </div>

              <div className="sol-title">
                <div className="sol-svg --pcview">
                  <svg
                    className="svg"
                    viewBox="0 0 1161 390"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M250 33V106C250 143.555 280.445 174 318 174V174"
                      stroke="#D7EBFF"
                      strokeWidth="3"
                    />
                    <path
                      d="M250 277V242C250 204.445 280.445 174 318 174V174"
                      stroke="#D7EBFF"
                      strokeWidth="3"
                    />
                    <path
                      d="M914 294L914 241C914 203.997 884.003 174 847 174V174"
                      stroke="#D7EBFF"
                      strokeWidth="3"
                    />
                    <path
                      d="M914 33L914 107C914 144.003 884.003 174 847 174V174"
                      stroke="#D7EBFF"
                      strokeWidth="3"
                    />
                    <circle
                      className="element div01"
                      cx="251"
                      cy="17"
                      r="11"
                      fill="#007DF1"
                      stroke="#D7EBFF"
                      strokeWidth="10"
                    />
                    <circle
                      className="element div02"
                      cx="914"
                      cy="17"
                      r="11"
                      fill="#007DF1"
                      stroke="#D7EBFF"
                      strokeWidth="10"
                    />
                    <circle
                      className="element div03"
                      cx="251"
                      cy="293"
                      r="11"
                      fill="#007DF1"
                      stroke="#D7EBFF"
                      strokeWidth="10"
                    />
                    <circle
                      className="element div04"
                      cx="914"
                      cy="294"
                      r="11"
                      fill="#007DF1"
                      stroke="#D7EBFF"
                      strokeWidth="10"
                    />
                  </svg>
                </div>

                <h4>
                  SuperPCMaster <br />
                  <span className="round">GPMS</span>
                </h4>
              </div>
            </div>
          </div>
        </section>
        {/* 티맥스 구름 특징 - 사업 (활용분야) */}
        <section id="osp511203" className="tmaxgr-feature--business">
          <ul className="business-list w-default">
            <li>
              <i className="icon icon-tmaxgr-business-co"></i>
              <h6>공공</h6>
              <span className="bar"></span>
              <p>
                개방형 OS 확산 지침에 따른 <br />
                대안 OS 사업에 특화
                <br />
                (접속 단말OS / VDI GuestOS)
              </p>
            </li>
            <li>
              <i className="icon icon-tmaxgr-business-fi"></i>
              <h6>금융</h6>
              <span className="bar"></span>
              <p>
                높은 보안성을 실현한
                <br />
                원격접속 OS 환경 제공
              </p>
            </li>
            <li>
              <i className="icon icon-tmaxgr-business-ent"></i>
              <h6>엔터프라이즈</h6>
              <span className="bar"></span>
              <p>
                OS 통합 관리가 가능한 <br />
                강력한 중앙 통제 환경 제공
              </p>
            </li>
          </ul>
        </section>
        {/* 티맥스 구름 특징 - 모델 */}
        <section className="tmaxgr-feature--model">
          <h2>Tmax구름 활용 모델</h2>

          <ul className="model-list w-default">
            <li className="office">
              <h6>사무실</h6>
              <span className="img img-tmaxgr-model-off"></span>
            </li>
            <li className="homework">
              <h6>출장/재택</h6>
              <span className="img img-tmaxgr-model-hwork"></span>
            </li>
          </ul>
        </section>
        {/* 티맥스 구름 특징 - 레퍼런스 */}
        <section className="tmaxgr-feature--ref">
          <h2>레퍼런스</h2>

          <ul className="ref-list w-default">
            <li>
              <h4>망분리 환경의 인터넷망 PC OS</h4>
              <div className="ref-text">
                <p>
                  망분리 환경에서의 인터넷망 <br />
                  PC OS VDI GuestOS로 활용
                </p>
              </div>
              <span className="img ref-img-01"></span>
            </li>
            <li>
              <h4>원격 접속 단말 OS</h4>
              <div className="ref-text">
                <p>
                  VDI 또는 물리 PC에 접속하는 <br />
                  단말기 OS로 활용
                </p>
              </div>
              <span className="img ref-img-02"></span>
            </li>
            <li>
              <h4>회의실 공용 PC</h4>
              <div className="ref-text">
                <p>회의실 PC의 VDI Client OS로 활용</p>
              </div>
              <span className="img ref-img-03"></span>
            </li>
            <li>
              <h4>업무용 PC 및 중앙통제 관리</h4>
              <div className="ref-text">
                <p>
                  PC통제 및 자원 관리 <br />※ GPMS 적용
                </p>
              </div>
              <span className="img ref-img-04"></span>
            </li>
          </ul>
        </section>
  </React.Fragment>
}