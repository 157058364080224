const TabBar = ({ children }) => {
  const numberOfChildrens = children.length;

  return (
    <div className="tab__bar-style">
      <ul className={`w-default tab-size-${numberOfChildrens}`}>
        {children}
      </ul>
    </div>
  );
};

// TODO: add new state function
TabBar.Tab = ({ index, value, label, onClick }) => {
  const isFocused = index === value;

  return (
    <li>
      <button 
        value={index} 
        className={isFocused ? 'focused' : ''}
        onClick={onClick}
      >
        {label}
        {/* not yet, new state : <span className={"alert-new": "alert-new --n"}>2</span> */}
      </button>
    </li>
  );
};

export default TabBar;
