import { useState } from 'react';
import { Link } from 'react-router-dom';

import { t } from 'data/lang/lang.transform';

import { links } from 'data/data_link';
import { header_gnb_depth1, header_gnb_depth2 } from 'data/data_site_navigation';

import { ButtonLinkHR } from 'components/Button/Buttons';
import { IconArrowFoldDown } from 'components/common/Icons';

export default function Footer() {
  const [btnFooterState, setBtnFooterState] = useState(Array(5).fill(false));

  const handleBtnFooterDepth = (event, index) => {
    event.preventDefault();

    let swipeArr = [...btnFooterState];
    swipeArr[index] = !btnFooterState[index];

    setBtnFooterState([...swipeArr]);
  };

  return (
    <footer>
      <div className="footer-sitemap">
        <ul className="sitemap-depth1 w-default">
          {
            header_gnb_depth1.map((item, index) => {
              const {id, text} = item;

              return <li key={id}>
                <h4 className="title-depth1">
                  <button className='btn btn-toggle-depth1'
                    aria-expanded={btnFooterState[index]}
                    onClick={(event) => {
                      handleBtnFooterDepth(event, index);
                    }}
                  >
                    <span>{text}</span>
                    <IconArrowFoldDown />
                  </button>
                </h4>
                <ul role='menu' className="sitemap-depth2" aria-hidden={!btnFooterState[index]}>
                  {
                    header_gnb_depth2[index].map((item2, index) => {
                      return <li role='menuitem' key={index}>
                        <Link className='btn-link-depth2' to={item2.path}>{item2.text}</Link>
                      </li>
                    })
                  }
                </ul>
              </li>
            })
          }
          <li className="another-site">
            <ButtonLinkHR />
          </li>
        </ul>
      </div>
      <div className="footer-information w-default">
        <div className="logo-wrapper">
          <Link to={links.main} className="logo">
            <img
              src={process.env.PUBLIC_URL + '/asset/icon/logo_blue.svg'}
              alt="logo"
            />
          </Link>
          {/* <ul className='information-rules'>
          <li><Link to={links.osp8112}><strong>이용약관</strong></Link></li>
          <li><Link to={links.osp8112}><strong>개인정보처리방침</strong></Link></li>
          </ul> */}
          <div className="information">
            <address>{t("ADDRESS_HQ")}</address>
            <small>Copyright 2022. TmaxOS Corp. All rights reserved</small>
          </div>
          <button className="btn-certificate">
            <img
              src={process.env.PUBLIC_URL + '/asset/images/mark-certificate.png'}
              alt="logo"
            />
          </button>
        </div>
      </div>
    </footer>
  );
}
