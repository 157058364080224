import { t } from 'data/lang/lang.transform';

const CustomerBanner = () => {
  
  const InquiryCall = () => (
    <div className="inquiry-content --call">
      <span className="title">전화 문의</span>
      <a href={`tel:+82 18005566`} className="btn-addr">
        {t('inforContactNumber')}
      </a>
      <p>&#40;{t('inforWorkTime')}&#41;</p>
    </div>
  );
  
  const InquiryEmail = () => (
    <div className="inquiry-content --email">
      <span className="title">이메일 문의</span>
      <a href={`mailto:${t('inforEmail')}`} className="btn-addr">
        {t('inforEmail')}
      </a>
      <p>
        &#40;토요일/일요일/공휴일에 이메일로 문의하신 건은 휴일 이후 평일에 답변
        메일을 받으실 수 있습니다&#41;
      </p>
    </div>
  );

  return <section className="customer-banner">
    <div className="customer-banner-container w-default">
      <span className="img img-customer-banner"></span>
      <div className="banner-title">
        <h5>무엇이든 물어보세요!</h5>
        <div className="temp-layout">
          <InquiryCall />
          <InquiryEmail />
        </div>
      </div>
    </div>
  </section>
};

export default CustomerBanner;
