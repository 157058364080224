// temp osp7121
import PageBanner from 'components/Banner/Banner.Page';
import { t } from 'data/lang/lang.transform';

export default function OSP7121() {
  const handleNumberCopyClick = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(t('inforContactNumber'));
    window.alert('전화 번호가 복사되었습니다');
  };

  const handleEmailCopyClick = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText('tmaxossupport@tmax.co.kr');
    window.alert('이메일이 복사되었습니다');
  };
  return (
    <>
      <PageBanner page={2} depth2Index={1} />
      <main className="main-osp7121">
        <section className="w-default">
          <hgroup>
            <h2>
              <strong>티맥스오에스</strong>에 궁금한 점이 있으신가요?
            </h2>
            <h3>
              문의하신 내용에 대해 빠른 시일 내에 이메일로 답변 드리겠습니다.
            </h3>
          </hgroup>

          <div className="temp-layout-inquiry">
            <div className="inquiry-content">
              <i className="icon icon-temp-inquiry-call"></i>
              <span className="title">전화 문의</span>
              <button onClick={handleNumberCopyClick}>
                <address className="contact-number">{t('inforContactNumber')}</address>
              </button>
              <p>{t('inforWorkTime')}</p>
            </div>
            <div className="inquiry-content --email">
              <i className="icon icon-temp-inquiry-email"></i>
              <span className="title">이메일 문의</span>
              <button onClick={handleEmailCopyClick}>
                <address className="contact-email">
                  {t('inforEmail')}
                </address>
              </button>
              <p>
                토요일/일요일/공휴일에 이메일로 문의하신 건은 휴일 이후 평일에
                답변 메일을 받으실 수 있습니다
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
