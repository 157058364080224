/**
 * <TabBoxed>
 *  <TabBoxed.Col>
 * </TabBoxed>
 */

import { useEffect } from 'react';

const TabBoxed = ({ content, main, children }) => {
  const header = document.querySelector('.header');

  useEffect(() => {
    const tab = document.querySelector('.tab-boxed-fix');
    const banner = document.querySelector('.subpage-banner');

    const elementMain = document.querySelector(`.${main}`);
    const contents = document.querySelectorAll(`.${content}`);

    const options = {
      root: null,
      rootMargin: '50px 0px 0px 0px',
      threshold: [0.1, 0.3, 0.5, 0.7, 1.0],
    };

    const callback = (entries) => {
      const [entry] = entries;

      if (!entry.isIntersecting) {
        tab.classList.add('fixed');
        elementMain.classList.add('fixed');
        header.classList.add('slideout');
      } else {
        tab.classList.remove('fixed');
        elementMain.classList.remove('fixed');
        header.classList.remove('slideout');
      }
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(banner);

    const contentOptions = {
      root: null,
      rootMargin: '50px 0px 0px 0px',
      threshold: [1.0],
    };

    const turnActive = (entries) => {
      const [entry] = entries;

      if (content === 'history') {
        entry.target.classList.add('--active');

        const element = entry.target.parentElement;

        if (element.id) {
          const tabbtns = document.querySelectorAll('.btn-tab');
          tabbtns.forEach((btn) => {
            btn.value === element.id
              ? btn.classList.add('focused')
              : btn.classList.remove('focused');
          });
        }
      } else {
      }
    };

    const contentsObserver = new IntersectionObserver(
      turnActive,
      contentOptions
    );
    contents.forEach((content) => contentsObserver.observe(content));

    return () => {
      observer.unobserve(banner);
      contents.forEach((content) => contentsObserver.unobserve(content));
      // FIXME: 페이지 이동시 HEADER 사라짐 버그 임시 prevent
      header.classList.remove('slideout');
    };
  });

  return (
    <div className="tab-boxed-fix">
      <ul className="tab-list">{children}</ul>
    </div>
  );
};

TabBoxed.Col = ({ id, item, index }) => {
  const onClickToContent = (e) => {
    e.preventDefault();
    const content = document.querySelector(`#${id}`);
    const contentTop = window.scrollY + content.getBoundingClientRect().top;

    window.scrollTo(0, contentTop - 98);
  };

  return (
    <li>
      <button
        className={index === 0 ? 'btn-tab focused' : 'btn-tab'}
        value={id}
        onClick={onClickToContent}
      >
        {item}
      </button>
    </li>
  );
};

export default TabBoxed;
