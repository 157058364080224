/**
 * todo: img태그로 바꾸기
 * todo: toggle event는 퀄리티를 좀 올려야함.
 */
import { useState } from 'react';

export default function MainOSP4114() {
  const certificateList = [
    {
      title: 'GS 인증',
      img: 'gs인증 이미지 입니다',
    },
    {
      title: '직접생산확인증명서',
      img: '직접생산확인증명서 이미지 입니다',
    },
    {
      title: '기업부설연구소 인증서',
      img: '기업부설연구소 인증서 이미지 입니다',
    },
  ];

  const [toggle, setToggle] = useState({
    index: 0,
    state: false,
  });

  const onClick = (index, state = false) => {
    setToggle({
      ...toggle,
      index,
      state,
    });
  };

  return (
    <main className="main-osp4114">
      <section className="certificate__container background-01">
        <h2>인증내역</h2>
        <ul className="certificate-list">
          {certificateList.map((item, index) => {
            return (
              <li key={index}>
                <button
                  className="btn-check-certificate"
                  onClick={() => onClick(index, true)}
                >
                  <span className="icon icon-certificate"></span>
                  <span className="lb-certificate">
                    <span>{item.title}</span>
                    <i className="icon icon-table_f_ar_r"></i>
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      </section>

      {toggle.state && (
        <div
          className="certificate-toggle__wrapper"
          onClick={() => onClick(0, false)}
        >
          <div className="toggle__container">
            <div className="toggle-control">
              <span>{certificateList[toggle.index].title}</span>
              <button onClick={() => onClick(toggle.index, false)}>
                <i className="icon icon-close-white"></i>
              </button>
            </div>
            <div className="toggle-certificate__wrapper">
              <div className="toggle-certificate">
                {toggle.index === 0 && <Certificate01 title={certificateList[toggle.index].title} />}
                {toggle.index === 1 && <Certificate02 title={certificateList[toggle.index].title} />}
                {toggle.index === 2 && <Certificate03 title={certificateList[toggle.index].title} />}
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

const Certificate01 = ({title}) => {
  return <>
    <img
      src={
        process.env.PUBLIC_URL +
        `/asset/images/certificate/00-1.png`
      }
      alt={title}
      className="img-certificate"
    />
    <img
      src={
        process.env.PUBLIC_URL +
        `/asset/images/certificate/00-2.png`
      }
      alt={title}
      className="img-certificate"
    />
  </>
}

const Certificate02 = ({title}) => {
  return <>
  <img
    src={
      process.env.PUBLIC_URL +
      `/asset/images/certificate/01-1.png`
    }
    alt={title}
    className="img-certificate"
  />
  <img
    src={
      process.env.PUBLIC_URL +
      `/asset/images/certificate/01-2.png`
    }
    alt={title}
    className="img-certificate"
  />
</>
}

const Certificate03 = ({title}) => {
  return <img
    src={
      process.env.PUBLIC_URL +
      `/asset/images/certificate/02.png`
    }
    alt={title}
    className="img-certificate"
  />
}