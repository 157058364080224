import React from 'react';

import PageBanner from 'components/Banner/Banner.Page';
import CustomerBanner from 'components/Banner/Banner.Customer';
import NoticeText from './Product.NoticeText';
import { TabBoxedStyle } from 'components/Tab/TabStyle';

export default function OSP5113() {
  return (
    <>
      <PageBanner page={1} depth2Index={1} />
      <main className="main-osp5113">
        <TabBoxedStyle
          props={{
            id: ['osp511301', 'osp511302', 'osp511303'],
            page: 'osp5113',
          }}
        />
        <TmaxgrSDFeatureContainer />
        <NoticeText label={"Tmax구름 SD 소개서"} product={"Tmax구름SD"} />
        <CustomerBanner />
      </main>
    </>
  );
}

const TmaxgrSDFeatureContainer = () => {
  return <React.Fragment>
        {/* 티맥스 구름SD 특징 - 메인 (제품 소개) */}
        <section id="osp511301" className="tmaxgrsd-feature--main">
          {/* 메인 일러스트 */}
          <div className="illust-tmaxgrsd-main w-default">
            <span className="svg illust-man">
              {/* 말풍선1 - 블루*/}
              <span className="svg bubble --bl">
                원격/재택 근무시
                <strong> 보안성과 사용성이 고민이라면?</strong>
              </span>

              {/* 말풍선2 - 민트 */}
              <span className="svg bubble --mt">
                원격접속 단말기를 통한
                <strong> 보안 사고가 걱정된다면?</strong>
              </span>
            </span>
          </div>

          <h1>
            Tmax구름 SD는
            <span className="highlight --pcview">
              안전한 원격/재택 근무 환경을 위한 필요 요소
            </span>
            <span className="highlight --mobileview"> 안전한 원격/재택 </span>
            <span className="highlight --mobileview">
              근무 환경을 위한 필요 요소
            </span>
            를 충족합니다.
          </h1>

          <div className="main-feature">
            <ul className="feature-list w-default">
              <li>
                <h4>검증된 단말기 및 중앙관제</h4>
                <div className="feature-depth2">
                  <p>OS 커널 검증을 통한 단말기 신뢰성 확보</p>
                  <p>단말기 중앙관리 및 실시간 모니터링</p>
                </div>
              </li>
              <li>
                <h4>신뢰된 네트워크</h4>
                <div className="feature-depth2">
                  <p>인증된 사용자만 네트워크 접속 가능</p>
                  <p>암호화된 통신으로 정보 유출 차단</p>
                </div>
              </li>
              <li>
                <h4>안전한 망분리 환경</h4>
                <div className="feature-depth2">
                  <p>중요정보 유출 차단 및 침해범위 최소화</p>
                  <p>보안영역 분리를 통한 해킹공격 집중 방어</p>
                </div>
              </li>
            </ul>
          </div>

          {/* 메인 질문들 */}
          <div className="main-qna">
            <div className="qna-cont width1350">
              <div className="qna-question">
                <h3>주요 FAQ</h3>
              </div>
              {/* qna 리스트 */}
              <ul className="qna-list">
                <li>
                  <span className="number-icon">1</span>
                  <h4>원격근무 환경에서도 강력한 보안성이 유지되나요?</h4>
                  <p>
                    Tmax 구름 SD 는 다중 보안 인증 (FDE/VPN/2 차 인증 및 구름 OS
                    보안 4 종이 탑재되어, 사무실에서의 안전한
                  </p>
                  <p>
                    근무환경 뿐만 아니라 원격 근무 시{' '}
                    <strong> 접속 단말의 보안</strong> 을 지원합니다.
                  </p>
                  <p>
                    이에 사용자 및 외부 요인에 의해 발생할 수 있는 위험이
                    원천적으로 차단되고,<strong> 안전한 원격 접속 환경</strong>
                    이 제공됩니다.
                  </p>
                </li>
                <li>
                  <span className="number-icon">2</span>
                  <h4>업무망/인터넷망 환경의 자유로운 이동이 가능한가요?</h4>
                  <p>
                    단말 OS가 제공하는 기능 중 하나인 단말 제어바의 망 선택
                    아이콘을 통해 클릭만으로 빠르고 편하게 이동 가능합니다.{' '}
                  </p>
                </li>
                <li>
                  <span className="number-icon">3</span>
                  <h4>
                    기존에 사용하던 (VDI/VM) 업무 환경을 그대로 활용할 수
                    있나요?
                  </h4>
                  <p>
                    Tmax구름 SD는 사용자 입장에서 기존 업무 환경에 가장 편하게
                    접속 가능하도록 개발되었습니다.
                  </p>
                </li>
                <li>
                  <span className="number-icon">4</span>
                  <h4>기업 별 근무 환경에 최적화 되어있나요? </h4>
                  <p>
                    TmaxOS는 타 사이트들의 시범 운영을 통해 공통 모듈을 선정하여
                    기업 별 환경에 최적화된 공통 기능들을{' '}
                  </p>
                  <p>
                    확립하였습니다. Tmax구름 SD는 기업 별 근무 환경에 최적화된
                    모듈을 제공함으로써 생산성을 극대화 합니다.
                  </p>
                </li>
                <li>
                  <span className="number-icon">5</span>
                  <h4>
                    더 자세한 설명 및 구성도를 알고 싶은데, 어떤 경로들을 통해
                    알 수 있을까요?
                  </h4>
                  <p>
                    [문의하기]채널을 통해 구성 환경, 활용 방안 등의 상세한
                    설명이 담겨있는 [Tmax구름 SD소개서]를 받고,{' '}
                  </p>
                  <p>담당자 연결을 통해 모든 궁금증을 해결하세요.</p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* 티맥스 구름SD 특징 - 사업 */}
        <section id="osp511302" className="tmaxgrsd-feature--business">
          <ul className="business-list w-default">
            <li>
              <i className="icon icon-tmaxgrsd-business-cu"></i>
              <h6>고객 환경 최적화</h6>
              <p>
                타 사이트들의 운영을 통해 선정된 <br />
                공통 기능들로 기관/기업의 근무 환경에 <br />
                최적화된 모듈 제공
              </p>
            </li>
            <li>
              <i className="icon icon-tmaxgrsd-business-sec"></i>
              <h6>강력한 보안성</h6>
              <p>
                다중 보안 인증 및 구름 보안 모듈 탑재로 <br />
                안전한 근무환경 뿐만 아니라 <br />
                접속 단말 보안을 지원
              </p>
            </li>
            <li>
              <i className="icon icon-tmaxgrsd-business-us"></i>
              <h6>간편한 사용성</h6>
              <p>
                한 대의 PC로 기존 업무환경(VDI/VM)을 <br />
                그대로 사용하여 사용자 입장에서 <br />
                업무 환경에 가장 편하게 접속 가능
              </p>
            </li>
          </ul>
        </section>

        {/* 티맥스 구름SD 특징 - 레퍼런스 */}
        <section id="osp511303" className="tmaxgrsd-feature--ref">
          {/* 레퍼런스 - 주요 */}
          <div className="ref-core width1340">
            <h4>주요레퍼런스</h4>
            <ul className="core-list">
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
              <li>
                <i className="icon icon-core-logo-"></i>
              </li>
            </ul>
          </div>

          {/* 레퍼런스 - 순서 */}
          <div className="ref-order">
            <h4>원격접속 실행 순서</h4>

            <ol className="order-list">
              <li>
                <i className="icon icon-tamxgrsd-order-01"></i>
                <p>단말기 부팅</p>
                <i className="icon icon-more">
                  <svg
                    width="23"
                    height="16"
                    viewBox="0 0 23 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5093 6.7269C21.0254 6.7269 21.4437 7.14524 21.4437 7.66129L21.4437 8.27341C21.4437 8.78946 21.0254 9.2078 20.5093 9.2078L1.53439 9.20775C1.01834 9.20775 0.599998 8.78941 0.599998 8.27336L0.599998 7.66125C0.599998 7.1452 1.01834 6.72685 1.53439 6.72685L20.5093 6.7269Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M23 8C23.0006 7.76117 22.9102 7.52211 22.7287 7.33929L15.7161 0.276058C15.3516 -0.0910871 14.7581 -0.0921546 14.3923 0.273677L13.9595 0.706496C13.5955 1.07047 13.5944 1.66027 13.9571 2.02555L19.8887 8L13.9571 13.9745C13.5944 14.3397 13.5955 14.9295 13.9595 15.2935L14.3923 15.7263C14.7581 16.0922 15.3516 16.0911 15.7161 15.7239L22.7287 8.66071C22.9102 8.47789 23.0006 8.23883 23 8Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </i>
              </li>
              <li>
                <i className="icon icon-tamxgrsd-order-02"></i>
                <p>네트워크 접속</p>
                <i className="icon icon-more">
                  <svg
                    width="23"
                    height="16"
                    viewBox="0 0 23 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5093 6.7269C21.0254 6.7269 21.4437 7.14524 21.4437 7.66129L21.4437 8.27341C21.4437 8.78946 21.0254 9.2078 20.5093 9.2078L1.53439 9.20775C1.01834 9.20775 0.599998 8.78941 0.599998 8.27336L0.599998 7.66125C0.599998 7.1452 1.01834 6.72685 1.53439 6.72685L20.5093 6.7269Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M23 8C23.0006 7.76117 22.9102 7.52211 22.7287 7.33929L15.7161 0.276058C15.3516 -0.0910871 14.7581 -0.0921546 14.3923 0.273677L13.9595 0.706496C13.5955 1.07047 13.5944 1.66027 13.9571 2.02555L19.8887 8L13.9571 13.9745C13.5944 14.3397 13.5955 14.9295 13.9595 15.2935L14.3923 15.7263C14.7581 16.0922 15.3516 16.0911 15.7161 15.7239L22.7287 8.66071C22.9102 8.47789 23.0006 8.23883 23 8Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </i>
              </li>
              <li>
                <i className="icon icon-tamxgrsd-order-03"></i>
                <p>VPN 선행인증</p>
                <i className="icon icon-more">
                  <svg
                    width="23"
                    height="16"
                    viewBox="0 0 23 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5093 6.7269C21.0254 6.7269 21.4437 7.14524 21.4437 7.66129L21.4437 8.27341C21.4437 8.78946 21.0254 9.2078 20.5093 9.2078L1.53439 9.20775C1.01834 9.20775 0.599998 8.78941 0.599998 8.27336L0.599998 7.66125C0.599998 7.1452 1.01834 6.72685 1.53439 6.72685L20.5093 6.7269Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M23 8C23.0006 7.76117 22.9102 7.52211 22.7287 7.33929L15.7161 0.276058C15.3516 -0.0910871 14.7581 -0.0921546 14.3923 0.273677L13.9595 0.706496C13.5955 1.07047 13.5944 1.66027 13.9571 2.02555L19.8887 8L13.9571 13.9745C13.5944 14.3397 13.5955 14.9295 13.9595 15.2935L14.3923 15.7263C14.7581 16.0922 15.3516 16.0911 15.7161 15.7239L22.7287 8.66071C22.9102 8.47789 23.0006 8.23883 23 8Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </i>
              </li>
              <li>
                <i className="icon icon-tamxgrsd-order-04"></i>
                <p>GPMS 인증</p>
                <i className="icon icon-more">
                  <svg
                    width="23"
                    height="16"
                    viewBox="0 0 23 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5093 6.7269C21.0254 6.7269 21.4437 7.14524 21.4437 7.66129L21.4437 8.27341C21.4437 8.78946 21.0254 9.2078 20.5093 9.2078L1.53439 9.20775C1.01834 9.20775 0.599998 8.78941 0.599998 8.27336L0.599998 7.66125C0.599998 7.1452 1.01834 6.72685 1.53439 6.72685L20.5093 6.7269Z"
                      fill="#B0B0B0"
                    />
                    <path
                      d="M23 8C23.0006 7.76117 22.9102 7.52211 22.7287 7.33929L15.7161 0.276058C15.3516 -0.0910871 14.7581 -0.0921546 14.3923 0.273677L13.9595 0.706496C13.5955 1.07047 13.5944 1.66027 13.9571 2.02555L19.8887 8L13.9571 13.9745C13.5944 14.3397 13.5955 14.9295 13.9595 15.2935L14.3923 15.7263C14.7581 16.0922 15.3516 16.0911 15.7161 15.7239L22.7287 8.66071C22.9102 8.47789 23.0006 8.23883 23 8Z"
                      fill="#B0B0B0"
                    />
                  </svg>
                </i>
              </li>
              <li>
                <i className="icon icon-tamxgrsd-order-05"></i>
                <p>업무망 접속</p>
              </li>
            </ol>
          </div>

          {/* 러퍼런스 - 예시 */}
          <div className="ref-expl">
            <h4>실행 화면 예시</h4>
            <ol className="expl-list">
              <li>
                <span className="img img-ref-expl-01"></span>
                <span className="number-icon">1</span>
                <p className="--pcview">
                  사용자 근무 위치에 맞는 <br />
                  업무 유형 선택 후 네트워크 설정
                </p>

                <p className="--mobileview">
                  사용자 근무 위치에 맞는 업무 유형 선택 후 네트워크 설정
                </p>
              </li>
              <li>
                <span className="img img-ref-expl-02"></span>
                <span className="number-icon">2</span>
                <p>사용자 인증용 정보 입력</p>
              </li>
              <li>
                <span className="img img-ref-expl-03"></span>
                <span className="number-icon">3</span>
                <p>필요 시 2차 인증 진행</p>
              </li>
            </ol>
          </div>
        </section>
  </React.Fragment>
}