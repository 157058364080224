import { Route, Routes } from 'react-router-dom';

import { links } from 'data/data_link';

// main
import OSP3111 from 'pages/main/OSP3111';
// tmaxos TmaxOS 소개
import IntroduceCompany from 'pages/tmaxos/Page.IntroduceCompany'; // 회사소개
import OSP4116 from 'pages/tmaxos/OSP4116'; // 연혁
import OSP4117 from 'pages/tmaxos/OSP4117'; // 오시는 길
// product 제품
import OSP5111 from 'pages/product/OSP5111'; // 개요
import OSP5112 from 'pages/product/OSP5112'; // Tmax구름
import OSP5113 from 'pages/product/OSP5113'; // Tmax구름 SD
import OSP5114 from 'pages/product/OSP5114'; // SuperRDS
// customer 고객지원
import OSP7116 from 'pages/customer/OSP7116';
import OSP7117 from 'pages/customer/OSP7117';
import PageNoticeBoard from 'pages/customer/Customer.Page.NoticeBoard';
import TempOSP7121 from 'pages/customer/OSP7121'; // import OSP7121 from 'pages/customer/OSP7121';
import OSP7126 from 'pages/customer/OSP7126';
// etc
import NotFound from 'pages/etc/NotFound';

const TmaxOsRouter = () => {
  return <>
    <Routes>
      <Route path='*' element={<NotFound />} />
      <Route path={links.main} element={<OSP3111 />} />

      <Route path={links.osp4111} element={<IntroduceCompany />} />
      <Route path={links.osp4116} element={<OSP4116 />} />
      <Route path={links.osp4117} element={<OSP4117 />} />
      
      <Route path={links.osp5111} element={<OSP5111 />} />
      <Route path={links.osp5112} element={<OSP5112 />} />
      <Route path={links.osp5113} element={<OSP5113 />} />
      <Route path={links.osp5114} element={<OSP5114 />} /> 

      <Route path={links.osp7116} element={<OSP7116 />}>
        <Route path='' element={<PageNoticeBoard />} />
        <Route path=':id' element={<OSP7117 />} />
      </Route>
      <Route path={links.osp7121} element={<TempOSP7121 />} />
      <Route path={links.osp7126} element={<OSP7126 />} />
    </Routes>
  </>
}

export default TmaxOsRouter;