import React, { useEffect, useState } from 'react';

import CustomerBanner from '../../components/Banner/Banner.Customer';
import MainProducts from './Main.Products';
import MainBanner from './Main.Banner.Tmaxgr';
import DialogPromoteMain from 'components/Dialog/DialogPromoteMain';

import { promotionEndDay, isOverEndDay } from 'func/handleDate';
import { stateShowDialog, hasCookie } from 'func/handleCookies';

export default function OSP3111() {
  const [open, setOpen] = useState(true);

  const closeDialog = () => {
    setOpen(false);
  }

  const openDialog = () => {
    setOpen(true);
  }

  useEffect(() => {
    if(!isOverEndDay(promotionEndDay) && !hasCookie(stateShowDialog)){
      openDialog();
    }
  },[]);

  return (
    <>
      <MainBanner />
      {
        open && <DialogPromoteMain closeDialog={closeDialog} />
      }
      {/* <SuperWeekBanner /> */}
      {/* old : <MainBannerSlider /> */}

      <main className="main-osp3111">
        {/* <MainNotice props={{
        link_promote_news: links.osp6112,
        // ...mainNotice
      }} /> */}

        <MainProducts />

        {/* <PromoteMainBoard />
      <PromoteMainNews /> */}
        <div style={{ paddingTop: '5vh' }}></div>
        <CustomerBanner />
      </main>
    </>
  );
}
