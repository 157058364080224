import Twinkles from './Main.Banner.Tmaxgr.Svg';
import { MainBannerTitle } from './Main.Banner.Components';
import ButtonMore from 'components/Button/Button.More';

import { links } from 'data/data_link';

const MainBanner = () => {
  return (
    <section className="banner-main">
      <Twinkles>
        <Twinkles.CircleBig />
        <Twinkles.Planet />
        <Twinkles.CircleSmall />
        <Twinkles.Line04 />
        <Twinkles.Line03 />
        <Twinkles.Line01 />
        <Twinkles.Line02 />
        {/* <Twinkles.FireWork /> */}
      </Twinkles>

      <div className="banner-contents">
        <div className="banner-contents__container w-default">
          <div className="banner-title__container">
            <MainBannerTitle />
            <MainBannerTitle.FireWork />

            <div className="banner-sub-title">
              <p>
                <span className="sub-title-tmaxgr"></span>
                은 누구나 자유롭게 <br className="--mobileview" /> 사용 가능한
                개방형OS입니다. <br />
              </p>
              <p>
                <span
                  className="--pcview"
                  style={{ paddingLeft: '0.4em' }}
                ></span>{' '}
                무료 다운로드를 통해 새로운 PC환경을 경험해보세요!
              </p>
            </div>
          </div>
          <div className="--pcview" style={{ alignSelf: 'end' }}>
            <ButtonMore title='Tmax구름 다운로드 하러 가기' path={links.osp7126}>{'다운로드 하러 가기'}</ButtonMore>
          </div>
          <div className="--mobileview" style={{ alignSelf: 'center' }}>
            <ButtonMore path={links.osp7126}>{'자세히 보기'}</ButtonMore>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
