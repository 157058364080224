const Twinkles = ({ children }) => {
  return (
    <div
      className="banner-twinkles"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <div className="banner-twinkles__container">{children}</div>
    </div>
  );
};

Twinkles.Planet = () => {
  return (
    <div className="fixed__container">
      <span className="planet --pcview">
        <svg
          width="734"
          height="627"
          viewBox="0 0 734 627"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1416_6475)">
            <circle
              cx="73.0015"
              cy="399.999"
              r="12.2923"
              transform="rotate(45 73.0015 399.999)"
              fill="#049DB1"
            />
            <circle
              cx="73.0007"
              cy="399.999"
              r="41.6193"
              transform="rotate(45 73.0007 399.999)"
              stroke="#048D9F"
              strokeOpacity="0.26"
              strokeWidth="20"
            />
            <path
              d="M73.5642 405C106.113 555.356 239.903 668 400.002 668V667C307.792 667 224.336 629.522 164.036 568.966C119.716 524.833 87.7571 468.295 73.8064 405H73.5642Z"
              fill="#048D9F"
              fillOpacity="0.2"
            />
          </g>
          <defs>
            <clipPath id="clip0_1416_6475">
              <rect width="734" height="627" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>
      <span className="planet --mobileview">
        <svg
          width="346"
          height="491"
          viewBox="0 0 346 491"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.8672 379C70.9561 485.908 174.092 562 295 562V561C174.653 561 71.9765 485.345 31.9357 379H30.8672Z"
            fill="#048D9F"
            fillOpacity="0.2"
          />
          <circle
            cx="30.9995"
            cy="379"
            r="5.21985"
            transform="rotate(45 30.9995 379)"
            fill="#049DB1"
          />
          <circle
            cx="30.999"
            cy="378.999"
            r="16.9198"
            transform="rotate(45 30.999 378.999)"
            stroke="#048D9F"
            strokeOpacity="0.16"
            strokeWidth="10"
          />
        </svg>
      </span>
    </div>
  );
};

Twinkles.FireWork = () => {
  return (
    <span className="fire-work">
      <svg
        width="61"
        height="61"
        viewBox="0 0 61 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 2.5C28 1.11929 29.1193 0 30.5 0C31.8807 0 33 1.11929 33 2.5V18.5C33 19.8807 31.8807 21 30.5 21C29.1193 21 28 19.8807 28 18.5V2.5Z"
          fill="url(#paint0_linear_1416_6476)"
        />
        <path
          d="M28 42.5C28 41.1193 29.1193 40 30.5 40C31.8807 40 33 41.1193 33 42.5V58.5C33 59.8807 31.8807 61 30.5 61C29.1193 61 28 59.8807 28 58.5V42.5Z"
          fill="url(#paint1_linear_1416_6476)"
        />
        <path
          d="M58.5 28C59.8807 28 61 29.1193 61 30.5C61 31.8807 59.8807 33 58.5 33H42.5C41.1193 33 40 31.8807 40 30.5C40 29.1193 41.1193 28 42.5 28H58.5Z"
          fill="url(#paint2_linear_1416_6476)"
        />
        <path
          d="M18.5 28C19.8807 28 21 29.1193 21 30.5C21 31.8807 19.8807 33 18.5 33H2.5C1.11929 33 -6.03528e-08 31.8807 0 30.5C6.03528e-08 29.1193 1.11929 28 2.5 28H18.5Z"
          fill="url(#paint3_linear_1416_6476)"
        />
        <path
          d="M48.5312 8.93324C49.5075 7.95693 51.0905 7.95693 52.0668 8.93324C53.0431 9.90955 53.0431 11.4925 52.0668 12.4688L40.7531 23.7825C39.7767 24.7588 38.1938 24.7588 37.2175 23.7825C36.2412 22.8062 36.2412 21.2233 37.2175 20.247L48.5312 8.93324Z"
          fill="url(#paint4_linear_1416_6476)"
        />
        <path
          d="M20.247 37.2175C21.2233 36.2412 22.8062 36.2412 23.7825 37.2175C24.7588 38.1938 24.7588 39.7767 23.7825 40.753L12.4688 52.0668C11.4925 53.0431 9.90955 53.0431 8.93324 52.0668C7.95693 51.0904 7.95693 49.5075 8.93324 48.5312L20.247 37.2175Z"
          fill="url(#paint5_linear_1416_6476)"
        />
        <path
          d="M52.0668 48.5312C53.0431 49.5075 53.0431 51.0904 52.0668 52.0668C51.0904 53.0431 49.5075 53.0431 48.5312 52.0668L37.2175 40.753C36.2412 39.7767 36.2412 38.1938 37.2175 37.2175C38.1938 36.2412 39.7767 36.2412 40.753 37.2175L52.0668 48.5312Z"
          fill="url(#paint6_linear_1416_6476)"
        />
        <path
          d="M23.7825 20.247C24.7588 21.2233 24.7588 22.8062 23.7825 23.7825C22.8062 24.7588 21.2233 24.7588 20.247 23.7825L8.93324 12.4688C7.95693 11.4925 7.95693 9.90955 8.93324 8.93324C9.90956 7.95693 11.4925 7.95693 12.4688 8.93324L23.7825 20.247Z"
          fill="url(#paint7_linear_1416_6476)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1416_6476"
            x1="63.0333"
            y1="5.08333"
            x2="-36.6"
            y2="104.717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8B39FF" />
            <stop offset="0.602041" stopColor="#4BE0FF" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

Twinkles.Line01 = () => {
  return (
    <span className="line-01">
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="192.334"
          width="272"
          height="10"
          rx="5"
          transform="rotate(-45 0 192.334)"
          fill="url(#paint0_linear_2019_42712)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2019_42712"
            x1="-8.95692"
            y1="197.31"
            x2="188.326"
            y2="197.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#57EBFF" />
            <stop offset="0.21875" stopColor="#57A3FF" />
            <stop offset="1" stopColor="#BF57FF" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

Twinkles.Line02 = () => {
  return (
    <span className="line-02">
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="192.334"
          width="272"
          height="10"
          rx="5"
          transform="rotate(-45 0 192.334)"
          fill="url(#paint0_linear_2019_42712)"
          fillOpacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2019_42712"
            x1="-8.95692"
            y1="197.31"
            x2="188.326"
            y2="197.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#57EBFF" />
            <stop offset="0.21875" stopColor="#57A3FF" />
            <stop offset="1" stopColor="#BF57FF" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

Twinkles.Line03 = () => {
  return (
    <span className="line-03">
      <svg
        width="200"
        height="200"
        viewBox="0 0 200 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="192.334"
          width="272"
          height="10"
          rx="5"
          transform="rotate(-45 0 192.334)"
          fill="url(#paint0_linear_2019_42708)"
          fillOpacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2019_42708"
            x1="-8.95691"
            y1="197.31"
            x2="136.445"
            y2="197.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#57A3FF" />
            <stop offset="1" stopColor="#BF57FF" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

Twinkles.Line04 = () => {
  return (
    <span className="line-04">
      <svg
        width="192"
        height="200"
        viewBox="0 0 192 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="192.334"
          width="272"
          height="10"
          rx="5"
          transform="rotate(-45 0 192.334)"
          fill="url(#paint0_linear_2019_45663)"
          fillOpacity="0.5"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2019_45663"
            x1="-8.95692"
            y1="197.31"
            x2="188.326"
            y2="197.31"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#57A3FF" />
            <stop offset="1" stopColor="#BF57FF" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </span>
  );
};

Twinkles.CircleBig = () => {
  return (
    <div className="fixed__container">
      <span className="circle-big">
        <svg
          width="1022"
          height="1022"
          viewBox="0 0 1022 1022"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="511"
            cy="511"
            r="511"
            fill="url(#paint0_linear_2019_42715)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2019_42715"
              x1="-43.5172"
              y1="812.326"
              x2="1058.22"
              y2="314.783"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#11137B" />
              <stop offset="1" stopColor="#28159E" />
            </linearGradient>
          </defs>
        </svg>
      </span>
    </div>
  );
};

Twinkles.CircleSmall = () => {
  return (
    <div className="fixed__container">
      <span className="circle-small">
        <svg
          width="132"
          height="132"
          viewBox="0 0 132 132"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="66"
            cy="66"
            r="66"
            fill="url(#paint0_linear_2019_42716)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2019_42716"
              x1="-5.62062"
              y1="104.919"
              x2="136.679"
              y2="40.6569"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#11137B" />
              <stop offset="1" stopColor="#28159E" />
            </linearGradient>
          </defs>
        </svg>
      </span>
    </div>
  );
};

export default Twinkles;
