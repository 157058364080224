import { t } from 'data/lang/lang.transform';

export default function NotFound() {
  return (
    <section className="notfound-wrap">
      <div className="notfound-container">
        <img
          src={process.env.PUBLIC_URL + '/asset/images/404.png'}
          alt="notfound"
        />
        <h2>요청하신 페이지를 찾을 수 없습니다.</h2>
        <p>
          방문하려는 페이지의 주소가 잘못 입력되었거나, 변경 또는 삭제되어
          페이지를 표시할 수 없습니다. <br />
          입력한 주소가 정확한지 다시 한번 확인해 주세요.
          <br />
          지속적인 오류 발생 시, 아래의 고객지원으로 연락주시면 빠르게
          조치하겠습니다.
          <br />
        </p>
        <address>
          <i className="icon icon-call"></i>
          <a href={`tel:${t('inforContactNumber')}`}>
            {t('inforContactNumber')}
          </a>
          <span>&#40;{t('inforWorkTime')}&#41;</span>
        </address>
      </div>
    </section>
  );
}
