import { useState } from 'react';
import { Link } from 'react-router-dom';

import { header_gnb_depth1, header_gnb_depth2 } from 'data/data_site_navigation';
import { IconPlus } from 'components/common/Icons';
import { ButtonNaviLogoHome } from './Header.Button';

import { ButtonLinkHR, ButtonLinkNewsLetter } from 'components/Button/Buttons';
import { IconHamburger, IconLnbClose } from 'components/common/Icons';

const NavMobile = () => {
  const depth1List = [...header_gnb_depth1];
  const depth2List = [...header_gnb_depth2];

  const [focusIndex, setFocusIndex] = useState(-1);
  const [openGnb, setOpen] = useState(false);

  const toggleGnb = () => {
    setOpen(!openGnb);
  };

  const closeGnb = () => {
    setOpen(false);
  };

  const handleGnbMobileClick = (index) => {
    if (index === focusIndex) {
      setFocusIndex(-1);
    } else {
      setFocusIndex(index);
    }
  };

  const onClickBackdrop = (event) => {
    return event.target.classList.contains("gnb-open") && closeGnb() 
  }

  return <nav
    className='nav-mobile'
    aria-hidden={!openGnb}
    onClick={onClickBackdrop}
  >
    <div className="gnb-depth1-m__wrapper">
      <div className="depth1-m-logo">
        <ButtonNaviLogoHome onClick={closeGnb} />
      </div>
      <div className="depth1-m-link">
        <ButtonLinkNewsLetter />
      </div>
      <div className="depth1-m-hamburger">
        <button className="btn-hamburger" onClick={toggleGnb}>
          {openGnb ? <IconLnbClose /> : <IconHamburger />}
        </button>
      </div>
    </div>
    {openGnb && (
      <div className="gnb-open">
        <ul className="gnb-depth1-list-m">
          {depth1List.map((item, index) => {
            return (
              <li key={item.id}>
                <div
                  className="btn-list-depth2"
                  aria-expanded={focusIndex === index}
                  onClick={() => handleGnbMobileClick(index)}
                >
                  <button className="btn-gnb-depth1-m">
                    <span>{item.text}</span>
                    <IconPlus />
                  </button>
                </div>
                <GnbMobileDepth2 list={depth2List[index]} onClick={closeGnb} />
              </li>
            );
          })}
          <li><ButtonLinkHR /></li>
        </ul>
      </div>
    )}
  </nav>
};

export default NavMobile;

const GnbMobileDepth2 = ({ list, onClick }) => {
  return (
    <ul className="lnb-depth2">
      {list.map((item, index) => {
        const {text, path} = item;
        
        return (
          <li key={index}>
            <Link
              to={path}
              className="btn-lnb-depth2-m"
              onClick={onClick}
            >
              <span>{text}</span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};