import React, { useState } from 'react';

import PageBanner from 'components/Banner/Banner.Page';
import TabBar from 'components/Tab/Tab.Bar';

import MainOSP4111 from './OSP4111';
// import MainOSP4112 from './OSP4112';
import MainOSP4113 from './OSP4113';
import MainOSP4114 from './OSP4114';

const IntroduceCompany = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const onClickTab = (event) => {
    const { target: { value } } = event;
    setTabIndex(Number(value));
  }

  const tabContents = {
    0: <MainOSP4111 />,
    1: <MainOSP4113 />,
    2: <MainOSP4114 />,
    "default": <>잘못된 접근</>,
  };
  
  const renderTabContents = (number) => {
    return tabContents[number] || tabContents.default;
  };

  return (
    <>
      <PageBanner page={0} depth2Index={0} />
      <TabBar>
        <TabBar.Tab index={0} value={tabIndex} label={'TmaxOS'} onClick={onClickTab} />
        {/* <TabBar.Tab index={1} value={tabIndex} label={"CEO 인사말"} /> */}
        <TabBar.Tab index={1} value={tabIndex} label={'조직도 연구인력'} onClick={onClickTab} />
        <TabBar.Tab index={2} value={tabIndex} label={'인증내역'} onClick={onClickTab} />
      </TabBar>
      {renderTabContents(tabIndex)}
    </>
  );
};

export default IntroduceCompany;
