import { links } from "./data_link";

const header_nav = [
  {
    h5: 'TmaxOS 소개',
    path: links.osp4111,
    img_banner: 'img_banner-subpage-1', // img넣을 class이름 
    span: [
      '개방형 OS 시장을 선도하고 신사업을 발굴해 다방면의 서비스를 제공합니다.',
    ],
    ul: [
      {
        path: links.osp4111,
        text: '회사소개',
        p: [
          '개방형 OS 시장을 선도하고 신사업을 발굴해 다방면의 서비스를 제공합니다.',
        ],
      },
      {
        path: links.osp4116,
        text: '연혁',
        p: [
          '대한민국을 넘어 글로벌 SW기업으로 도약하기 위해 도전하고 상상합니다.',
        ],
      },
      {
        path: links.osp4117,
        text: '오시는 길',
        p: [
          '대한민국을 넘어 글로벌 SW기업으로 도약하기 위해 도전하고 상상합니다.'
        ],
      },
    ]
  },
  {
    h5: '제품',
    path: links.osp5111,
    img_banner: 'img_banner-subpage-2',
    span: [
      '더욱 빠르고 편리하게, TmaxOS를 통해 원격 근무에 가장 최적화된 솔루션을 경험해 보세요.',
    ],
    ul: [
      {
        path: links.osp5112,
        text: 'Tmax구름',
        p: [
          '국가보안기술연구소의 구름 보안 프레임워크에',
          'TmaxOS 자체 개발 기술력으로 완성한 개방형 운영 체제'
        ],
      },
      {
        path: links.osp5113,
        text: 'Tmax구름 SD',
        p: [
          '강력한 보안성과 사용성을 기반으로 원격접속 단말기에 최적화된 개방형OS입니다.'
        ],
      },
      {
        path: links.osp5114,
        text: 'SuperRDS',
        p: ['Tmax구름 SD와 결합한 안전하고 간편한 원격 접속 솔루션입니다.'],
      },
    ]
  },
  // {
  //   h5: '홍보',
  //   path: links.osp6115,
  //   img_banner: 'img_banner-subpage-3',
  //   span: [
  //     '티맥스오에스의 언론 보도와 다양한 최근 소식을 제공합니다.',
  //   ],
  //   ul: [
  //     {
  //       path: links.osp6115,
  //       text: 'TmaxOS 소식',
  //       p: ['이벤트, 내/외부 행사, 서포터즈 활동 등 티맥스오에스의 다양한 활동과 소식을 제공합니다.']
  //     },
  //     {
  //       path: links.osp6112,
  //       text: '뉴스룸',
  //       p: ['티맥스오에스의 새로운 소식입니다.']
  //     },
  //     // {
  //     //   path: links.osp6114,
  //     //   text: '홍보영상',
  //     //   p: ['티맥스오에스의 소식을 영상으로 만나보세요.']
  //     // },
  //   ]
  // },
  {
    h5: '고객지원',
    path: links.osp7116,
    img_banner: 'img_banner-subpage-4',
    span: [
      '티맥스오에스에 대한 궁금증을 편리하게 해결하세요.',
    ],
    ul: [
      {
        path: links.osp7116,
        text: '공지사항',
        p: ['회사와 제품에 대한 새로운 소식을 전달합니다.']
      },
      // {
      //   path: links.osp7120,
      //   text: '자주하는 질문',
      //   p: ['티맥스오에스에 자주하는 질문과 답을 살펴보세요.']
      // },
      {
        path: links.osp7121,
        text: '문의하기',
        p: ['티맥스오에스에 대해 궁금한 점을 문의해 주세요.']
      },
      {
        path: links.osp7126,
        text: '다운로드',
        p: ['Tmax구름을 무료로 체험해 보세요.']
      }
    ]
  },
];


const header_gnb_depth1 = [
  {
    id: 0,
    text: "TmaxOS 소개",
    path: links.osp4111,
    lb_button: "자세히 보기",
    sub_text: '개방형 OS 시장을 선도하고 신사업을 발굴해 다방면의 서비스를 제공합니다.',
  },
  {
    id: 1,
    text: "제품",
    path: links.osp5111,
    lb_button: "모든 제품 보기",
    sub_text: '더욱 빠르고 편리하게, TmaxOS를 통해 원격 근무에 가장 최적화된 솔루션을 경험해 보세요.',
  },
  // {
  //   id: 2,
  //   text: "홍보",
  //   path: links.osp6115,
  //   lb_button: "최근 소식 보기",
  //   sub_text: '티맥스오에스의 언론 보도와 다양한 최근 소식을 제공합니다.',
  // },
  {
    id: 2,
    text: "고객지원",
    path: links.osp7116,
    lb_button: "문의하기",
    sub_text: '티맥스오에스에 대한 궁금증을 편리하게 해결하세요.',
  }
];

const header_gnb_depth2 = [
  [
    {
      id: 0,
      path: links.osp4111,
      text: '회사소개',
      sub_text: '개방형 OS 시장을 선도하고 신사업을 발굴해 다방면의 서비스를 제공합니다.',
    },
    {
      id: 1,
      path: links.osp4116,
      text: '연혁',
      sub_text: '대한민국을 넘어 글로벌 SW기업으로 도약하기 위해 도전하고 상상합니다.',
    },
    {
      id: 2,
      path: links.osp4117,
      text: '오시는 길',
      sub_text: '대한민국을 넘어 글로벌 SW기업으로 도약하기 위해 도전하고 상상합니다.'
    },
  ],
  [
    {
      id: 0,
      path: links.osp5112,
      text: 'Tmax구름',
      sub_text: '국가보안기술연구소의 구름 보안 프레임워크에 TmaxOS 자체 개발 기술력으로 완성한 개방형 운영 체제'
    },
    {
      id: 1,
      path: links.osp5113,
      text: 'Tmax구름 SD',
      sub_text: 'Tmax구름의 검증된 보안성 및 사용성을 기반으로 사용자 환경에 최적화된 보안 단말'
    },
    {
      id: 2,
      path: links.osp5114,
      text: 'SuperRDS',
      sub_text: 'Tmax구름 SD와 결합한 안전하고 간편한 원격 접속 솔루션',
    }
  ],
  // [
    // {
    //   id: 0,
    //   path: links.osp6115,
    //   text: 'TmaxOS 소식',
    //   sub_text: '이벤트, 내/외부 행사, 서포터즈 활동 등 티맥스오에스의 다양한 활동과 소식을 제공합니다.'
    // },
    // {
    //   id: 1,
    //   path: links.osp6112,
    //   text: '뉴스룸',
    //   sub_text: '티맥스오에스의 새로운 소식입니다.'
    // },
  // ],
  [
    {
      id: 0,
      path: links.osp7116,
      text: '공지사항',
      sub_text: '회사와 제품에 대한 새로운 소식을 전달합니다.'
    },
    {
      id: 1,
      path: links.osp7121,
      text: '문의하기',
      sub_text: '티맥스오에스에 대해 궁금한 점을 문의해 주세요.'
    },
    {
      id: 2,
      path: links.osp7126,
      text: '다운로드',
      sub_text: 'Tmax구름을 무료로 체험하고 제품별 사용 방법을 확인해 보세요.'
    }
  ]
]


export {
  header_nav,
  header_gnb_depth1,
  header_gnb_depth2
};