const product_list = [
  {
    id: 'p_tmaxgr',
    path: "/product/tmaxgr",
    name: 'Tmax구름',
    explain: ' 데비안 리눅스 기반 개방형 운영체제로 강력한 {br } 보안 모듈을 탑재하여 다양한 사용자가 편리하고{br } 안전하게 사용할 수 있는 OS 환경을 제공합니다.',
    m_explain: ' 데비안 리눅스 기반 개방형 운영체제로{br } 강력한 보안 모듈을 탑재하여{br } 다양한 사용자가 편리하고 안전하게 {br } 사용할 수 있는 OS 환경을 제공합니다.',
  },
  {
    id: 'p_tmaxgrsd',
    path: "/product/sd",
    name: 'Tmax구름 SD',
    explain: '강력한 보안성과 사용성을 기반으로 {br } 원격접속 단말기에 최적화된 개방형OS입니다.',
    m_explain: '강력한 보안성과 사용성을 기반으로 {br } 원격접속 단말기에 최적화된 {br } 개방형OS입니다.',
  },
  {
    id: 'p_superrds',
    path: "/product/rds",
    name: 'SuperRDS',
    explain: 'Tmax구름 SD와 결합한 안전하고 간편한 {br }원격 접속 솔루션 입니다',
    m_explain: 'Tmax구름 SD와 결합한 안전하고{br } 간편한 원격 접속 솔루션 입니다',
  }
];

export{
  product_list
};