/**
 * stateShowDialog : 프로모션 다이얼로그 '오늘 하루 그만 보기' 
 * 
 */
const stateShowDialog = 'show_promote_dialog';

const setCookie = (cookieName, dueDate) => {
  const now = new Date(); 
  now.setDate(now.getDate() + dueDate); 

  document.cookie = `${cookieName}=${true}; Expires=${now.toUTCString()}`;
}

const hasCookie = (cookieName) => {
  const cookies = document.cookie.split(';').reduce((acc, cookie) => {
    const [name, value] = cookie.split('=').map(cookieString => cookieString.trim());
    return { ...acc, [name]: value };
  }, {});

  return cookies[cookieName] === 'true';
} 

export {
  stateShowDialog,
  hasCookie,
  setCookie
};