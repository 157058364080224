import { links } from '../../data/data_link';

const ButtonLinkHR = () => {
  return (
    <a
      href={links.HR}
      target={'_blank'}
      rel="noreferrer"
      className={'btn btn-gnb-depth1 btn-gnb-depth1-m --hr'}
      title='인재채용 소식보러 가기'
    >
      <span>인재채용</span>
      <i className="icon icon-open_in_new">
        <svg
          className="svg"
          width="9"
          height="18"
          viewBox="0 0 9 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.9263 3.324H8.0013C8.37409 3.324 8.6763 3.62621 8.6763 3.999V10.074C8.6763 10.4468 8.37409 10.749 8.0013 10.749C7.62851 10.749 7.3263 10.4468 7.3263 10.074V5.62859L1.2786 11.6763L0.324005 10.7217L6.37171 4.674H1.9263C1.55351 4.674 1.2513 4.37179 1.2513 3.999C1.2513 3.62621 1.55351 3.324 1.9263 3.324Z"
          />
        </svg>
      </i>
    </a>
  );
};

const ButtonLinkNewsLetter = () => {
  return (
    <a
      href={links.osLetter}
      target={'_blank'}
      rel="noreferrer"
      className={'btn btn-link-newsletter'}
      title='뉴스레터 구독하러 가기'
    >
      <span>뉴스레터 구독</span>
    </a> 
  )
}

export { ButtonLinkHR, ButtonLinkNewsLetter };
