/**
 * filter text
 * {b b} -> <strong></strong>
 * {br } -> <br />
 */

import { useRef } from "react";

const regBold = new RegExp(/((\{b)\s(.+?(\s\w+){0,})\s(b\}))/gi);
const regBr = new RegExp(/(\{br\s\})/gi);
const regSpace = new RegExp(/(\{tab\s\})/gi);

const useRegExp = (content) => {
  const text = useRef(content);

  const applyBold = text.current.replace(regBold, '<strong>$3</strong>')
  const applyBr = applyBold.replace(regBr, '<br />')
  const applySpace = applyBr.replace(regSpace, '<span>&nbsp;&nbsp;&nbsp;</span>')

  text.current = applySpace;

  return text.current;
};

export {
  regBr,
  regBold,
  regSpace,
  useRegExp
};