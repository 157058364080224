import { t } from 'data/lang/lang.transform';

const MainOSP4111 = () => {

  const InformationList = ({ children }) => (
    <ul className="information-list">{children}</ul>
  );

  return (
    <main className="main-osp4111">
      <section className="about__tmaxos w-default">
        <span className="img-about_tmaxos_banner" />
        <h2>
          개방형 OS확산을 이끌어갈 <br />
          국내 최대의 OS 전문 기업 <br />
          <strong>티맥스오에스</strong>
        </h2>
      </section>

      <div className="about__information">
        <div className="information__wrapper --infor01">
          <InformationList>
            <li>
              <strong>설립일</strong>
              <p>{t('inforFound')}</p>
            </li>
            <li>
              <strong>대표이사</strong>
              <p>{t('inforCEO')}</p>
            </li>
            <li>
              <strong>임직원(명)</strong>
              <p>
                {t('inforWorkers')}
                <small>22년 5월 기준</small>
              </p>
            </li>
          </InformationList>
        </div>
        <div className="information__wrapper --infor02">
          <strong>사업장 위치</strong>
          <InformationList>
            <li>
              <p>{t('HQ')}</p>
              <p>{t('ADDRESS_HQ')}</p>
            </li>
            <li>
              <p>{t('RnD')}</p>
              <p>{t('ADDRESS_RND')}</p>
            </li>
          </InformationList>
        </div>

        <div className="information__wrapper --infor03">
          <strong>사업분야 및 주요 제품</strong>
          <InformationList>
            <li>
              <span className="cate-product">운영체제(OS)</span>
              <div className="product-list">
                <p>Tmax구름</p>
                <p>Tmax구름 SD</p>
              </div>
            </li>
            <li>
              <span className="cate-product">원격 솔루션</span>
              <div className="product-list">
                <p>SuperRDS</p>
              </div>
            </li>
          </InformationList>
        </div>
      </div>
    </main>
  );
};

export default MainOSP4111;
