import PageBanner from 'components/Banner/Banner.Page';
import { IconDownLoad, IconInformation } from 'components/common/Icons';
import { links } from 'data/data_link';

export default function OSP7126() {
  return (
    <>
      <PageBanner page={2} depth2Index={2} />
      <main className="main-osp7126 custom">
        <section className="products-list background-01">
          <h2>
            강력한 보안 모듈을 탑재한 개방형 운영체제,
            <br />
            <strong>Tmax구름을 무료로 체험해 보세요!</strong>
          </h2>
          <ProductListContainer>
            <ProductItem product={'tmax-gr'}>
              <div className="product-infor">
                <h4>Tmax구름</h4>
                <p>
                  국가보안기술연구소의 구름 보안 프레임워크에 TmaxOS 자체 개발
                  기술력으로 완성한 개방형 운영체제
                </p>
                <div className="tmaxgr-spec">
                  <span className="spec-cate">최소 사양</span>
                  <ul className="spec-list">
                    <li>
                      <span className="spec-tit">CPU</span>
                      <span>2.5 GHz 2 core (Intel 3세대 이상) </span>
                    </li>
                    <li>
                      <span className="spec-tit">디스크</span>
                      <span>20GB</span>
                    </li>
                    <li>
                      <span className="spec-tit">메모리</span>
                      <span>8GB</span>
                    </li>
                    <li>
                      <span className="spec-tit">해상도</span>
                      <span>1024x768</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="btns-download">
                <a
                  href={links.grDownload}
                  target="_blank"
                  rel='noreferrer'
                  download={true}
                  className="btn btn-rounded-03 --install"
                >
                  <span>설치 파일(.iso)</span>
                  <IconDownLoad />
                </a>
              </div>
              <div className="--install --mobileview">
                <IconInformation />
                <span>무료 체험은 PC 버전에서만 가능합니다.</span>
              </div>
            </ProductItem>
          </ProductListContainer>
        </section>
      </main>
    </>
  );
}

const ProductListContainer = ({ children }) => {
  return <ul className="products width1218">
    {children}
  </ul>
}

const ProductItem = ({ product, children }) => {
  // 현재는 1개지만 원래 3개였음. 제품이 늘어날 경우 해당 태그를 사용하길 권장...
  return <li className={`product ${product}`}>
    {children}
  </li>
}