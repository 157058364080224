/**
 * * Outlet
 * board list or board content detail
 */
import React from 'react';
import { Outlet } from 'react-router-dom';

import PageBanner from 'components/Banner/Banner.Page';

export default function OSP7116() {
  return (
    <>
      <PageBanner page={2} depth2Index={0} />
      <Outlet />
    </>
  );
}
