import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ButtonNaviLogoHome } from './Header.Button';
import { ButtonLinkHR, ButtonLinkNewsLetter } from 'components/Button/Buttons';

import { header_gnb_depth1, header_gnb_depth2 } from 'data/data_site_navigation';

const NavPc = (props) => {
  const { path } = props;

  const depth1List = [...header_gnb_depth1];
  const depth2List = [...header_gnb_depth2];

  const [gnbIndex, setGnbIndex] = useState(0);
  const [depth2Visible, setDepth2Visible] = useState(false);

  useEffect(() => {
    setDepth2Visible(false);
  }, [path]);

  let depth2Timer;
  const onMouseEnter = (index = -1) => {
    setDepth2Visible(true);

    if (index !== -1) {
      setGnbIndex(index);
    }
    clearTimeout(depth2Timer);
  };

  const onMouseLeave = () => {
    depth2Timer = setTimeout(() => {
      setDepth2Visible(false);
    }, 500);
  };

  return <nav className="nav-pc">
    <div className="gnb-depth1__wrapper w-default">
      <div className="gnb-depth1__container" aria-label='main-navigation'>
        <div className="depth1-logo">
          <ButtonNaviLogoHome />
        </div>
        <ul className="depth1-list">
          {depth1List.map(item => {
            return (
              <li key={item.id}>
                <Link
                  onMouseEnter={() => onMouseEnter(item.id)}
                  onMouseLeave={onMouseLeave}
                  to={item.path}
                  data-focus={path === item.id}
                  className={'btn-gnb-depth1'}
                >
                  {item.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="gnb-depth1__container" aria-label='sub-navigation'>
        <ul className="depth1-list">
          <li><ButtonLinkNewsLetter /></li>
          <li><ButtonLinkHR /></li>
        </ul>
      </div>
    </div>
    <div
      className={'gnb-depth2__wrapper'}
      aria-hidden={!depth2Visible}
      onMouseEnter={(e) => onMouseEnter()}
      onMouseLeave={onMouseLeave}
    >
      <div className={'gnb-depth2__container w-default'}>
        <div className="depth2-title">
          <h5>{depth1List[gnbIndex].text}</h5>
          <p>{depth1List[gnbIndex].sub_text}</p>
          <Link
            to={depth1List[gnbIndex].path}
            className="btn btn-sub-link"
          >
            <span>{depth1List[gnbIndex].lb_button}</span>
          </Link>
        </div>
        <ul className="depth2-list">
          {depth2List[gnbIndex]?.map(item => {
            return (
              <li key={item.id}>
                <Link to={item.path}>{item.text}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  </nav>
}

export default NavPc;