const categoryNotice = [
  '일반',
  '제품'
];

// 작업용 dummy data
const testNotice = [
  {
    id: 4,
    path: '/customer/notice/111',
    title: "제품 test1",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 1,
    category: categoryNotice[1],
    date: "2000-08-06",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/112',
    title: "일반 test2",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2023-08-06",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/113',
    title: "일반 test3",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2000-08-06",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/113',
    title: "일반 test3",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2000-08-06",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/114',
    title: "제품 test4",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 1,
    category: categoryNotice[1],
    date: "2000-08-16",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/115',
    title: "test6",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2000-08-06",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/4',
    title: "test4",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2000-08-16",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/4',
    title: "test10",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2000-08-06",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/4',
    title: "test notice",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2000-08-16",
    notice_state: true,
  },
  {
    id: 4,
    path: '/customer/notice/4',
    title: "test4",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2000-08-16",
    notice_state: false,
  },
  {
    id: 4,
    path: '/customer/notice/4',
    title: "test new",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2023-08-16",
    notice_state: false,
  },
]

const customerNotice = [
  {
    id: 0,
    path: '/customer/notice/0',
    title: '[공지] Tmax구름 21.22.11.00 업데이트 되었습니다.',
    content: "[ TmaxGooroom 21.22.11.00 업데이트 내역 ]{br }TmaxGooroom 을 이용해주시는 사용자분들께 감사드립니다.{br }TmaxGooroom 21.22.11.00 버전 업데이트 내역을 아래에서 확인 부탁드립니다.{br }기존 사용자분들께서는 [소프트웨어 > 업데이트] 혹은 [제어판 > 정보 > 소프트웨어 업데이트]를 통해최신버전으로 업데이트가 가능하며,{br }처음으로 TmaxOS 를 사용하시는 경우 홈페이지 내 다운로드센터를 통해 TmaxGooroom 을 다운로드받아 설치하실 수 있습니다.{br }{br }- 기능 추가 및 보완 -{br }● 터치스크린 지원{br }● 터치펜 우클릭 기능 지원{br }● 블루투스 5.0 펌웨어 지원{br }● 저해상도 지원{br }● [도움말] 앱 추가{br }● [Tray > 소리] 음소거 및 음량 설정 동작 개선{br }● 기타 GUI 개선{br }{br }- 주요 버그 픽스 -{br }● 사운드카드 인식불가 현상 개선{br }● 파일매니저에서 이미지 섬네일 보이지 않는 현상 개선{br }● 터치패드 우클릭 동작 개선{br }{br }관련하여 문의사항이 있으신 경우, 저희 고객만족센터 및 이메일 문의를 통해 연락 부탁드립니다.{br }감사합니다. {br }<a href='https://osdownload.tmaxos.com:55556/download/TmaxOS/tmaxgooroom-21-common-stable.iso' download style='color: blue'>TmaxGooroom 21.22.11.00 다운로드</a>",
    category_id: 1,
    category: categoryNotice[1],
    date: "2022-11-24",
    notice_state: false,
  },
  {
    id: 1,
    path: '/customer/notice/1',
    title: '[공지] 2022 SuperWeek에서 SuperOS를 만나보세요.',
    content: "Tmax Group이 주관하는 2022 SuperWeek에서 {br } [새로운 세상을 만들다. SuperApp]이라는 주제로 모든 이의 삶을 바꿀 혁신적 기술을 소개합니다.{br } 혁신의 시작점에 있는 SuperOS를 만나러 TmaxOS 부스로 오세요! {br } {br } 사전등록 : 2022년 08월 08일(월)~2022년 09월 05일(월) {br } 추천인에 [OS]라고 입력하시고 인증해주시면 선착순 100분께 스타벅스 아메리카노 기프티콘을 드립니다! {br }행사 기간 : 2022년 9월 6일(화)~7일(수), 10:00~17:30 {br } OS 행사 내용 : {br }1. 부스 토크쇼 [SuperOS를 묻다]{br }2. 구름 SD, SuperRDS 제품 시연{br }3. Tmax 구름OS 체험",
    category_id: 0,
    category: categoryNotice[0],
    date: "2022-08-05",
    notice_state: false,
  },
  {
    id: 2,
    path: '/customer/notice/2',
    title: "[공지] TmaxOS의 홈페이지가 새로 오픈했습니다.",
    content: 'TmaxOS 홈페이지에 방문해 주셔서 감사합니다. {br } 개방형 OS 확산을 이끌어갈 국내 최대 OS 전문 기업 TmaxOS의 새로운 홈페이지가 오픈 하였습니다.{br } 많은 관심 부탁드립니다.',
    category_id: 0,
    category: categoryNotice[0],
    date: "2022-08-05",
    notice_state: false,
  },
  {
    id: 3,
    path: '/customer/notice/3',
    title: '[공지] Tmax구름 21.23.03.00 업데이트 되었습니다.',
    content: "[ TmaxGooroom 21.23.03.00 업데이트 내역 ]{br }TmaxGooroom 을 이용해주시는 사용자분들께 감사드립니다." +
    "{br }TmaxGooroom 21.23.03.00 버전 업데이트 내역을 아래에서 확인 부탁드립니다.{br }" +
    "처음 사용하시는 사용자인 경우, 고객지원 > 다운로드를 통해 ISO를 받아 설치하실 수 있습니다.{br }기존 사용자의 경우 재 설치하여 사용 부탁드리며, 데이터 백업 후 재설치해 주시길 바랍니다. {br }{br }"+
    "- 기능 추가 및 보완 -{br }● 인스톨러 UI 개선{br }● 알림 센터 기능 개선 (편의 기능, 알림 그룹화, 저해상도 등){br }● 편의 기능 사용성 개선{br }● 풀 디스크 암호화 앱 추가{br }● 기타 GUI 개선{br }● 커널 6.0 업데이트{br }● 펌웨어(20230210 버전) 업데이트{br }● 사운드 펌웨어 업데이트{br }{br }" +
    "- 주요 버그 픽스 -{br }● 알림 센터{br } {tab }- 그룹화 기능 개선{br } {tab }- 최신 알림 표시 개선{br } {tab } - 아이콘 개수 표시 오류 개선 {br }● 인스톨러 개선 {br }{tab } - text field 오기입 현상 개선 {br }{tab } - 풀 디스크 암호화 패키지 설치 개선{br }{br }" + 
    "관련하여 문의사항이 있으신 경우, 저희 고객만족센터 및 이메일 문의를 통해 연락 부탁드립니다.{br }감사합니다. {br }",
    category_id: 1,
    category: categoryNotice[1],
    date: "2023-04-06",
    notice_state: true,
  },
  // ...testNotice
];

const categoryQna = [
  'Tmax구름',
  'Tmax구름 SD',
  'SuperRDS',
  '기타'
]

const customerQna = [
  {
    id: 0,
    // path: toggle 
    title: "1 title",
    content: "1 content",
    category_id: 0,
    category: categoryQna[0],
  },
  {
    id: 1,
    title: "2 title",
    content: "2 content",
    category_id: 1,
    category: categoryQna[1],
  },
  {
    id: 2,
    title: "3 title",
    content: "3 content",
    category_id: 2,
    category: categoryQna[2],
  },
]

export { 
  categoryNotice,
  customerNotice,
  customerQna
};