import React, { useState } from "react";

import Dialog from "./Dialog";

import { stateShowDialog, setCookie } from "func/handleCookies";

// import IMG_PROMOTE_GRSD from 'assets/images/banner/img_banner_promote_tmaxgrSD.png';
import IMG_PROMOTE from 'assets/images/banner/img_promote_notice_1213.png';
// const TEST_PROMOTE_SD = '지금 구매하러 가기';
// const LINK_TO_PROMOTE_SD = 'https://bit.ly/OS_popup';
const LINK_TO_PROMOTE = 'https://home.tmaxcloud.com/?utm_source=tmaxoshomepage&utm_medium=cta+button&utm_campaign=merger_2312%EC%97%B0%EA%B2%B0%EC%9A%94%EC%B2%AD';

const DialogPromote = ({ closeDialog }) => {
  const [check, setCheck] = useState(false);

  const handleCheckbox = () => {
    setCheck(!check);
  }

  const handleClickClose = () => {
    if(check === true){
      setCookie(stateShowDialog, 1);
    }
    closeDialog();
  }

  return <Dialog className={'promote-gr-sd'}>
    <a className='btn-img-promote-sd' title="" href={LINK_TO_PROMOTE} target="_blank" rel={'noreferrer'}>
      <img src={IMG_PROMOTE} alt="notice_image" />
    </a>
    <div className="dialog-bottom">
      <button 
        className="dialog-control-condition btn"
        onClick={handleCheckbox}
      >
        <input 
          type="checkbox" 
          checked={check}
          onChange={handleCheckbox}
        />
        <span>오늘 하루 그만 보기</span>
      </button>
      <button className='btn btn-close' onClick={handleClickClose}>
        <span>닫기</span>
      </button>
    </div>
  </Dialog>
}

export default DialogPromote;