import { Link } from "react-router-dom";

import { links } from "data/data_link";
import pdf from 'assets/pdf/TmaxOS_Gooroom.pdf'

const NoticeText = ({label, product}) => {

  const downloadLink = {
    "Tmax구름": pdf,
    "Tmax구름SD": pdf,
    "SuperRDS": false,
  }

  return <div className="notice-text">
    <p>
      <Link to={links.osp7121}>&#91;문의하기&#93;</Link>
      채널을 통해 구성 환경, 활용 방안, 도입 효과 등의 상세한 설명이
      담겨있는
      <a 
        href={downloadLink[product]}
        download={"TmaxOS_Gooroom"}
      >
        &#91;{label}&#93; 
      </a>
      를 받고, 담당자 연결을 통해 모든 궁금증을 해결하세요.
    </p>
  </div>
}

export default NoticeText;
// vm 으로 window -> safari 설치