import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/_global.scss';

import {
  HashRouter,
  Routes,
  Route,
} from 'react-router-dom';

// import HelloTmax from './components/HelloTmax';
// import User from './components/User';
import Loading from './components/common/Loading';


ReactDOM.render(
  // <React.StrictMode>
  <HashRouter basename={'/'}>
    <Routes>
      <Route path="/*" element={<App />} />

      <Route path="/loading" element={<Loading />} />
      {/* <Route path="/index" element={<HelloTmax />} /> */}
      {/* <Route path='/user/*' element={<User />}/> */}
    </Routes>
  </HashRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
