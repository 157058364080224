import { Link } from 'react-router-dom';

import { links } from 'data/data_link';

const ButtonNaviLogoHome = ({ props }) => {
  return (
    <Link to={links.main} className="logo" {...props} />
  );
};

export { ButtonNaviLogoHome };
