import { Link } from 'react-router-dom';
import { IconMoreArrow } from 'components/common/Icons';

const ButtonMore = ({ path, href = false, children, title="" }) => {
  if (href) {
    return (
      <a href={href} className="btn btn-more" title={title}>
        <span>{children}</span>
        <IconMoreArrow />
      </a>
    );
  } else {
    return (
      <Link to={path} className="btn btn-more" title={title}>
        <span>{children}</span>
        <IconMoreArrow />
      </Link>
    );
  }
};

export default ButtonMore;
