const IconHamburger = () => {
  return (
    <i className="icon icon_btn-toggle">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H17.75C18.1642 3.75 18.5 4.08579 18.5 4.5C18.5 4.91421 18.1642 5.25 17.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5ZM1.5 10C1.5 9.58579 1.83579 9.25 2.25 9.25H17.75C18.1642 9.25 18.5 9.58579 18.5 10C18.5 10.4142 18.1642 10.75 17.75 10.75H2.25C1.83579 10.75 1.5 10.4142 1.5 10ZM2.25 14.75C1.83579 14.75 1.5 15.0858 1.5 15.5C1.5 15.9142 1.83579 16.25 2.25 16.25H17.75C18.1642 16.25 18.5 15.9142 18.5 15.5C18.5 15.0858 18.1642 14.75 17.75 14.75H2.25Z"
          fill="white"
        />
      </svg>
    </i>
  );
};

const IconPlus = () => (<i className="icon">
<svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className='svg-path'
      d="M8.6 2.59999C8.6 2.26862 8.33137 1.99999 8 1.99999C7.66863 1.99999 7.4 2.26862 7.4 2.59999L7.4 7.4L2.6 7.4C2.26862 7.4 2 7.66863 2 8C2 8.33137 2.26862 8.6 2.6 8.6L7.4 8.6V13.4C7.4 13.7314 7.66863 14 8 14C8.33137 14 8.6 13.7314 8.6 13.4L8.6 8.6L13.4 8.6C13.7314 8.6 14 8.33137 14 8C14 7.66863 13.7314 7.4 13.4 7.4L8.6 7.4L8.6 2.59999Z"
      fill="#666666"
    />
  </svg>
</i>)

const IconLnbClose = () => {
  return (
    <i className="icon">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="4.51953"
          y="3.45947"
          width="17"
          height="1.5"
          rx="0.75"
          transform="rotate(45 4.51953 3.45947)"
          fill="#212121"
        />
        <rect
          x="16.541"
          y="4.52002"
          width="17"
          height="1.5"
          rx="0.75"
          transform="rotate(135 16.541 4.52002)"
          fill="#212121"
        />
      </svg>
    </i>
  );
};

const IconArrowFoldDown = () => {
  return (
    <i className="icon icon-table_f_ar_d">
      <svg width="14" height="14" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.941 8.00016L20.591 15.6502C20.7773 15.8375 20.8818 16.091 20.8818 16.3552C20.8818 16.6193 20.7773 16.8728 20.591 17.0602C20.4981 17.1539 20.3875 17.2283 20.2656 17.2791C20.1438 17.3298 20.0131 17.356 19.881 17.356C19.749 17.356 19.6183 17.3298 19.4965 17.279C19.3746 17.2283 19.264 17.1539 19.171 17.0602L12.941 10.8302L6.71104 17.0602C6.61808 17.1539 6.50748 17.2283 6.38562 17.279C6.26376 17.3298 6.13306 17.356 6.00105 17.356C5.86903 17.356 5.73833 17.3298 5.61647 17.279C5.49461 17.2283 5.38401 17.1539 5.29104 17.0602C5.10479 16.8728 5.00025 16.6193 5.00025 16.3552C5.00025 16.091 5.10479 15.8375 5.29104 15.6502L12.941 8.00016Z" fill="rgba(115, 115, 115, 1)"/>
      </svg>
    </i>
  );
};

const IconMoreArrow = () => {
  return (
    <i className="icon icon-more_arrow">
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 6L11.795 6.705L14.085 9L2 9L2 10L14.085 10L11.79 12.295L12.5 13L16 9.5L12.5 6Z"
          fill="white"
        />
      </svg>
    </i>
  );
};

const IconDownLoad = () => {
  return (
    <i className="icon icon-down-svg">
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M23.2302 14.07C22.9477 13.7915 22.5669 13.6354 22.1702 13.6354C21.7735 13.6354 21.3927 13.7915 21.1102 14.07L18.3302 16.82V6.5C18.3302 6.10218 18.1722 5.72065 17.8909 5.43934C17.6096 5.15804 17.228 5 16.8302 5C16.4324 5 16.0508 5.15804 15.7695 5.43934C15.4882 5.72065 15.3302 6.10218 15.3302 6.5V16.82L12.5802 14.07C12.2959 13.805 11.9198 13.6608 11.5312 13.6677C11.1426 13.6745 10.7718 13.8319 10.497 14.1068C10.2221 14.3816 10.0647 14.7524 10.0579 15.141C10.051 15.5296 10.1952 15.9057 10.4602 16.19L15.7702 21.5C15.9081 21.6403 16.0734 21.7508 16.2557 21.8248C16.438 21.8987 16.6335 21.9345 16.8302 21.93C17.0267 21.9334 17.2219 21.897 17.4041 21.8231C17.5862 21.7492 17.7516 21.6393 17.8902 21.5L23.2302 16.19C23.3705 16.0514 23.4819 15.8864 23.5579 15.7044C23.6339 15.5224 23.6731 15.3272 23.6731 15.13C23.6731 14.9328 23.6339 14.7376 23.5579 14.5556C23.4819 14.3736 23.3705 14.2086 23.2302 14.07Z"></path>
        <path d="M28.16 28.3599H5.5C4.57174 28.3599 3.6815 27.9911 3.02513 27.3348C2.36875 26.6784 2 25.7881 2 24.8599V20.6299H5V24.8599C5 24.9925 5.05268 25.1197 5.14645 25.2134C5.24021 25.3072 5.36739 25.3599 5.5 25.3599H28.16C28.2918 25.3573 28.4175 25.3038 28.5107 25.2106C28.6039 25.1174 28.6574 24.9917 28.66 24.8599V20.6299H31.66V24.8599C31.66 25.3195 31.5695 25.7746 31.3936 26.1993C31.2177 26.6239 30.9599 27.0098 30.6349 27.3348C30.3099 27.6598 29.924 27.9176 29.4994 28.0935C29.0748 28.2694 28.6196 28.3599 28.16 28.3599Z"></path>
      </svg>
    </i>
  );
};

const IconOpenNew = () => {
  return (
    <i className="icon icon-open_in_new">
      <svg
        width="9"
        height="18"
        viewBox="0 0 9 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.9263 3.324H8.0013C8.37409 3.324 8.6763 3.62621 8.6763 3.999V10.074C8.6763 10.4468 8.37409 10.749 8.0013 10.749C7.62851 10.749 7.3263 10.4468 7.3263 10.074V5.62859L1.2786 11.6763L0.324005 10.7217L6.37171 4.674H1.9263C1.55351 4.674 1.2513 4.37179 1.2513 3.999C1.2513 3.62621 1.55351 3.324 1.9263 3.324Z"
        ></path>
      </svg>
    </i>
  );
};

const IconArrowRight = () => {
  return (
    <i className="icon icon-table_f_ar_r">
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_705_30476)">
          <path
            d="M17.9964 12.941L10.3464 20.591C10.1591 20.7773 9.90561 20.8818 9.64142 20.8818C9.37724 20.8818 9.12379 20.7773 8.93642 20.591C8.84269 20.4981 8.7683 20.3875 8.71753 20.2656C8.66676 20.1438 8.64063 20.0131 8.64063 19.881C8.64063 19.749 8.66676 19.6183 8.71753 19.4965C8.7683 19.3746 8.8427 19.264 8.93642 19.171L15.1664 12.941L8.93643 6.71104C8.8427 6.61808 8.7683 6.50748 8.71753 6.38562C8.66677 6.26376 8.64063 6.13306 8.64063 6.00105C8.64063 5.86903 8.66677 5.73833 8.71753 5.61647C8.7683 5.49461 8.8427 5.38401 8.93643 5.29104C9.12379 5.10479 9.37724 5.00025 9.64143 5.00025C9.90561 5.00025 10.1591 5.10479 10.3464 5.29104L17.9964 12.941Z"
            fill="#ffffff"
          />
        </g>
        <defs>
          <clipPath id="clip0_705_30476">
            <rect width="34" height="34" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </i>
  );
};

const IconError = () => {
  return (
    <i className="icon icon-error">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
          fill="#0AB4E2"
        />
      </svg>
    </i>
  );
};

// board state
const IconNewBoard = () => {
  return (
    <i className="icon icon-board-state-new">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10" cy="10" r="10" fill="#2C99FF" />
        <path
          d="M6.24023 14.4703H8.35259V11.4703C8.35259 10.3903 8.16248 9.16027 8.06742 8.17027H8.1308L9.04967 10.1203L11.4578 14.4703H13.7602V5.53027H11.6479V8.47027C11.6862 9.57475 11.7885 10.6764 11.9542 11.7703H11.8908L10.9719 9.77027L8.58495 5.53027H6.24023V14.4703Z"
          fill="white"
        />
      </svg>
    </i>
  );
};

const IconToggleState = ({ state }) => {
  if (state) {
    return (
      <i
        className="icon icon-table_f_ar_d"
        style={{
          transform: 'rotate(180deg)',
        }}
      ></i>
    );
  } else {
    return <i className="icon icon-table_f_ar_d"></i>;
  }
};

const IconInformation = () => {
  return (
    <i className="icon icon-information">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
          fill="#2C99FF"
        />
        <path
          d="M7.45 4C7.32 4 7.2 3.98 7.08 3.93C6.96 3.88 6.86 3.81 6.78 3.72C6.69 3.64 6.63 3.54 6.57 3.42C6.52 3.3 6.5 3.18 6.5 3.05C6.5 2.92 6.52 2.8 6.57 2.68C6.62 2.56 6.69 2.46 6.78 2.37C6.87 2.28 6.97 2.21 7.08 2.16C7.2 2.11 7.32 2.08 7.45 2.08C7.58 2.08 7.7 2.11 7.82 2.16C7.94 2.21 8.04 2.28 8.13 2.37C8.22 2.46 8.29 2.56 8.34 2.68C8.39 2.8 8.42 2.92 8.42 3.05C8.42 3.18 8.39 3.3 8.34 3.42C8.29 3.54 8.22 3.64 8.13 3.72C8.04 3.81 7.94 3.87 7.82 3.93C7.7 3.98 7.58 4 7.45 4ZM6.5898 11.07C6.2098 11.07 5.8998 10.96 5.6798 10.73C5.4298 10.48 5.2998 10.11 5.2998 9.63001C5.2998 9.40001 5.3298 9.14001 5.3898 8.84001L6.1998 4.99001H7.9298L7.0698 9.07001C7.0398 9.17001 7.0198 9.30001 7.0198 9.46001C7.0198 9.62001 7.0598 9.74001 7.1298 9.81001C7.2098 9.88001 7.3298 9.91001 7.5098 9.91001C7.6298 9.91001 7.7398 9.88001 7.8498 9.83001C7.9598 9.78001 8.0698 9.70001 8.1598 9.61001C8.3498 9.42001 8.4998 9.16001 8.5898 8.85001H9.0898C8.8798 9.46001 8.6098 9.94001 8.2798 10.3C8.0098 10.59 7.6998 10.8 7.3498 10.93C7.0998 11.02 6.8398 11.07 6.5598 11.07H6.5898Z"
          fill="white"
        />
      </svg>
    </i>
  );
};

export {
  IconHamburger,
  IconArrowFoldDown,
  IconMoreArrow,
  IconLnbClose,
  IconDownLoad,
  IconOpenNew,
  IconArrowRight,
  IconError,
  IconNewBoard,
  IconToggleState,
  IconPlus,
  IconInformation,
};
