import React from 'react';

import PageBanner from 'components/Banner/Banner.Page';
import NoticeText from './Product.NoticeText';
import { TabBoxedStyle } from '../../components/Tab/TabStyle';
import CustomerBanner from '../../components/Banner/Banner.Customer';

export default function OSP5114() {
  return (
    <>
      <PageBanner page={1} depth2Index={2} />
      <main className="main-osp5114">
        <TabBoxedStyle
          props={{
            id: ['osp511401', 'osp511402', 'osp511403'],
            page: 'osp5114',
          }}
        />
        <SuperRDSFeatureContainer />
        <NoticeText label={"SuperRDS 소개서"} product={"SuperRDS"} />
        <CustomerBanner />
      </main>
    </>
  );
}

const SuperRDSFeatureContainer = () => {
  return <React.Fragment>
        {/* superrds 특징 - 메인 */}
        <section id="osp511401" className="superrds-feature--main">
          <h1>
            포털을 통한 언제 어디서든
            <strong> 신속하고 원활한 원격 접속</strong>
          </h1>

          {/* 임시 이미지 */}
          <div className="illust-superrds-main w-default"></div>

          {/* 메인 특징들  */}
          <div className="main-feature">
            <ul className="feature-list">
              <li>
                <h4>국내외 주요 VDI 솔루션 지원</h4>
                <div className="feature-depth2">
                  <span className="img img-superrds-feature-vdi"></span>
                  <p>높은 호환성 제공으로 고객의 기존 환경 사용 가능</p>
                </div>
              </li>
              <li>
                <h4>자체 VDP 기술 적용</h4>
                <div className="feature-depth2">
                  <span className="img img-superrds-feature-tr"></span>
                  <p>낮은 트래픽으로 고해상도의 화면 전송</p>
                </div>
              </li>
            </ul>
          </div>

          <span className="bg"></span>
        </section>
        {/* superrds 특징 - 사업 */}
        <section id="osp511402" className="superrds-feature--business">
          <ul className="business-list w-default">
            <li>
              <i className="icon icon-superrds-business-sec"></i>
              <h6>안전한 원격 업무 환경</h6>
              <p>안전하고 신속한 업무망 접속 지원</p>
              <p>국보연 보안 모듈 탑재 단말기 보안 지원</p>
              <p>VPN 선행인증 지원</p>
            </li>
            <li>
              <i className="icon icon-superrds-business-fa"></i>
              <h6>빠르고 안정적인 접속</h6>
              <p>포털을 통한 신속하고 원활한 원격 접속</p>
              <p>자체 개발 프로토콜 (VDP) 기술 적용</p>
            </li>
            <li>
              <i className="icon icon-superrds-business-st"></i>
              <h6>강력한 통제 기능</h6>
              <p>원격 접속 사용자 보안 정책 설정 기능 제공</p>
              <p>사용 PC 및 접속 단말 통합관리 솔루션 적용</p>
            </li>
          </ul>

          <div className="business-explain">
            <h2>
              <span className="basic">
                조직/사용자 별 허용 기능 관리를 통한{' '}
              </span>
              <span className="highlight">
                강력한 사용자 권한 통제 기능 제공
              </span>
            </h2>
            <span className="svg svg-illust-expl"></span>
          </div>
        </section>
        {/* superrds 특징 - 예시 */}
        <section id="osp511403" className="superrds-feature--expl">
          <div className="expl-cont">
            <ul className="expl-list">
              <li>
                <h4>재택 근무</h4>
                <div className="expl-depth2">
                  <span className="img img-expl-hw"></span>
                  <p>
                    원활하고 신속한 <br />
                    재택근무 시스템 도입 및 안착
                  </p>
                </div>
              </li>
              <li>
                <h4>출장/외근</h4>
                <div className="expl-depth2">
                  <span className="img img-expl-out"></span>
                  <p>
                    출장 및 외근 시 <br />
                    노트북 한대로 원격 업무 가능
                  </p>
                </div>
              </li>
              <li>
                <h4>스마트 오피스</h4>
                <div className="expl-depth2">
                  <span className="img img-expl-so"></span>
                  <p>
                    공간 제약 없는 <br />
                    스마트오피스 업무 환경 구현
                  </p>
                </div>
              </li>
            </ul>

            <span className="bg"></span>
          </div>
        </section>

  </React.Fragment>
}