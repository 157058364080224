/**
 *
 */

import { useState, useEffect } from 'react';

const TabBoxedStyle = ({ props }) => {
    const header = document.querySelector('.header');
    // default
  const tab = {
    list: ['제품 소개', '특장점', '활용 분야'],
    id: ['tab1', 'tab2', 'tab3'],
    page: 'osp4116',
    // activeTab: 0,
    ...props,
  };

  const [boxFocus, setBoxFocus] = useState(0); // box의 focus 상태
  const [tabFix, setTabFix] = useState(false); // tab이 상단 고정된 상태

  // // click시 box focus 상태
  // click scroll 이동
  const onClick = (event, index = 0, elementID) => {
    event.preventDefault();

    const element = document.querySelector(`#${elementID}`);
    const elementTop = Math.floor(
      window.scrollY + element.getBoundingClientRect().top
    );

    // fix 된 tab보다는 덜 내려가야함
    // 98 tab height
    window.scrollTo(0, elementTop - 98);
  };

  const handleBoxFocus = (elementIDs) => {
    elementIDs.forEach((elementID, index) => {
      const element = document.querySelector(`#${elementID}`);
      const elementTop = element.getBoundingClientRect().top;
      const elementHeight = element.offsetHeight; // elemtn의 높이

      // scroll 이벤트의 기준 : 99 (98보다 조금 여유 주려고 )
      const eventStandard = Math.floor(elementTop + elementHeight - 99);

      // 영역 안에 들어오면, activeIndex update하기
      if (eventStandard >= 0 && eventStandard <= elementHeight) {
        // setActiveIndex = Number(index);
        setBoxFocus(Number(index));
      }
    });
  };

  // scroll시 tab fix 상태
  const onScroll = (event) => {

    // * 페이지 마다 바뀌어야 함
    // osp : 페이지
    // 페이지마다 margin 추가하는 css 작성
    const osp = document.querySelector(`.main-${tab.page}`);

    const bannerHeight =
      document.querySelector('.subpage-banner').offsetHeight - 25;
    // console.log(bannerHeight); // 464(web) // 231(mobile)

    // console.log(window.scrollY); // 450
    if (window.scrollY < bannerHeight) {
      header.style.transform = `translateY(0%)`;
    } else if (
      window.scrollY > bannerHeight &&
      window.scrollY <= bannerHeight + 100
    ) {
      header.style.transform = `translateY(${bannerHeight - window.scrollY}%)`;
      setTabFix(false);
      osp.classList.remove('fixed');
    }

    // * tab fix 상태
    else if (window.scrollY > bannerHeight + 100) {
        header.style.transform = 'translateY(-100%)';

      osp.classList.add('fixed');
      setTabFix(true);

      // fix 상태일때, 영역마다 box focus상태
      // setBoxFocus(handleBoxFocus(tab.id, boxFocus));
      handleBoxFocus(tab.id, boxFocus);
    } else {
      osp.classList.remove('fixed');
      setTabFix(false);
    }
  };

  // component mount에 따라서 event listener 생성 및 삭제
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      header.style.transform = `translateY(0%)`;
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <div className={tabFix ? 'tab-boxed-fix fixed' : 'tab-boxed-fix'}>
      <ul
        className="tab-list"
        // onClick={(event) => onClick(event)}
      >
        {tab.list.map((item, index) => {
          return (
            <li key={index}>
              <a
                href={'#' + tab.id[index]}
                // value={index}
                className={index === boxFocus ? 'focused btn-tab' : 'btn-tab'}
                // className="btn-tab"
                onClick={(event) => onClick(event, index, tab.id[index])}
              >
                {item}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

/**
 * * 바 스타일의 Tab
 * @exports to OSP4111 (TmaxOS 소개)
 *
 * todo: setTabNumber랑 이런거 default 값이 있어야 할거같은데...
 */

const TabBarStyle = ({ props }) => {
  const tab = {
    list: ['tab01', 'tab02', 'tab03'],
    commu: false,
    alert: false,
    ...props,
  };

  const onClick = (event) => {
    const {
      target: { value },
    } = event;

    if (value) {
      props.setTabNumber(Number(value));
    }
  };

  return (
    <>
      <div className={tab.commu ? 'tab__bar-style commu' : 'tab__bar-style'}>
        <ul
          className={`w-default tab-size-${tab.list.length}`}
          onClick={(event) => onClick(event)}
        >
          {tab.list.map((item, index) => {
            return (
              <li key={index}>
                <button
                  value={index}
                  className={props.tabNumber === index ? 'focused' : ''}
                >
                  {item}

                  {/* 임시로 놔둠  */}
                  <span
                    className={
                      props.alert && index === 2 ? 'alert-new' : 'alert-new --n'
                    }
                  >
                    2
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export { TabBoxedStyle, TabBarStyle };
